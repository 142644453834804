import React from 'react'
import { Link } from 'react-router-dom'

function Header() {
  return (
    <div className="header-top-bar">
        <div className="container-fluid">
            <div className="header-top">
                <div className="header-top-left">
                    <div className="header-notify">
                        First 20 students get 50% discount. <Link to="#">Hurry up!</Link>
                    </div>
                </div>
                <div className="header-top-right">
                    <ul className="header-info">
                        <li><Link to="#">Login</Link></li>
                        <li><Link to="#">Register</Link></li>
                        <li><Link to="tel:+016893440"><i className="icon-phone"></i>Call: +01 689 3440</Link></li>
                        <li><Link to="mailto:contact@utrains.org" target="_blank"><i className="icon-envelope"></i>Email: contact@utrains.org</Link></li>
                        <li className="social-icon">
                            <Link to="https://www.facebook.com/utrains.org"><i className="icon-facebook"></i></Link>
                            <Link to="#"><i className="icon-instagram"></i></Link>
                            <Link to="#"><i className="icon-twitter"></i></Link>
                            <Link to="https://www.linkedin.com/company/utrains/"><i className="icon-linkedin2"></i></Link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Header
