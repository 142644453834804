import React from 'react'
import { Link } from 'react-router-dom'

function Header() {
  return (
    <div className="header-category">
        <nav className="mainmenu-nav">
            <ul className="mainmenu">
                <li className="has-droupdown">
                    <Link to="#"><i className="icon-1"></i>Category</Link>
                    <ul className="submenu">
                        <li><Link to="/course_one">Design</Link></li>
                        <li><Link to="/course_one">Development</Link></li>
                        <li><Link to="/course_one">Architecture</Link></li>
                        <li><Link to="/course_one">Life Style</Link></li>
                        <li><Link to="/course_one">Data Science</Link></li>
                        <li><Link to="/course_one">Marketing</Link></li>
                        <li><Link to="/course_one">Music</Link></li>
                        <li><Link to="/course_one">Photography</Link></li>
                        <li><Link to="/course_one">Finance</Link></li>
                        <li><Link to="/course_one">Motivation</Link></li>
                    </ul>
                </li>
            </ul>
        </nav>
    </div>
  )
}

export default Header
