// Accordion1.js
import React, { useState } from 'react';

const Accordion1 = ({ items }) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="accordion">
      {items.map((item, index) => (
        <div key={index} className="accordion-item">
          <h5 className="accordion-header">
            <button
              className={`accordion-button ${activeIndex === index ? '' : 'collapsed'}`}
              type="button"
              onClick={() => toggleAccordion(index)}
            >
              {item.title}
            </button>
          </h5>
          <div
            className={`accordion-collapse collapse ${activeIndex === index ? 'show' : ''}`}
            style={{ height: activeIndex === index ? 'auto' : '0' }}
          >
            <div className="accordion-body">{item.content}</div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Accordion1;