/* eslint-disable no-unused-vars */

import Home from '../pages/main/Home';
import NextSession from '../pages/main/NextSession';
import CoursesList from '../pages/main/CoursesList';
import CourseDetails from '../pages/main/CourseDetails';
import SessionDetails from '../pages/main/SessionDetails';
import InterviewQuestions from '../pages/main/InterviewQuestions';
import ComingSoon from '../components/Coming-soon/ComingSoon';
import Faq from '../pages/main/FAQ';
import ContactUs from '../pages/main/ContactUs';
import Support from '../pages/main/Support';
import Feedback from '../pages/main/Feedback';
import Testimonial from '../pages/main/Testimonial';
import CompaniesStudentsWorked from '../pages/main/CompaniesStudentsWorked';
import Error404 from '../pages/main/Error404';
import routesPath from './routesPath';


const Routes = [
    {
        component: <Home/>,                                                                                               
        path: routesPath.home,
        meta: {},
    },
    {
        component: <NextSession/>,                                                                                               
        path: routesPath.nextSession,
        meta: {},
    },
    {
        component: <CoursesList/>,                                                                                               
        path: routesPath.courses,
        meta: {},
    },
    {
        component: <CourseDetails/>,                                                                                               
        path: routesPath.courseDetails,
        meta: {},
    },
    {
        component: <SessionDetails/>,                                                                                               
        path: routesPath.sessionsDetails,
        meta: {},
    },
    {
        component: <InterviewQuestions/>,                                                                                               
        path: routesPath.interviewquestions,
        meta: {},
    },
    {
        component: <ComingSoon/>,                                                                                               
        path: routesPath.ComingSoon,
        meta: {},
    },
    {
        component: <Support/>,                                                                                               
        path: routesPath.Support,
        meta: {},
    },
    {
        component: <ContactUs/>,                                                                                               
        path: routesPath.ContactUs,
        meta: {},
    },
    {
        component: <CompaniesStudentsWorked/>,                                                                                               
        path: routesPath.CompaniesStudentsWorked,
        meta: {},
        
    },
    {
        component: <Error404/>,                                                                                               
        path: routesPath.Error404,
        meta: {},
    },
    {
        component: <Faq/>,                                                                                               
        path: routesPath.Faq,
        meta: {},
    },
    {
        component: <Testimonial/>,                                                                                               
        path: routesPath.Testimonials,
        meta: {},
    },
    {
        component: <Feedback/>,                                                                                               
        path: routesPath.Feedback,
        meta: {},
    },
    {
        component: <Feedback/>,                                                                                               
        path: routesPath.Feedback,
        meta: {},
    },
   
]

export default Routes

