

// import { Link } from 'react-router-dom'

import React from 'react';
import BrandArea from './BrandArea';


function CompaniesStudentsWorkedContent() {
  
    return (
        <div >
            <div className="edu-cta-banner-area home-one-cta-wrapper bg-image">
                <div className="container">
                    <div className="edu-cta-banner">
                        <div className="row justify-content-center">
                            <div className="col-lg-7">
                                <div className="section-title section-center" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                                    <h2 className="title">Companies where   <span className="color-secondary">our students </span> have worked </h2>
                                </div>
                            </div>
                        </div>
                        <ul className="shape-group">
                            <li className="shape-01 scene">
                                <img data-depth="2.5" src="/assets/images/cta/shape-10.png" alt="shape" />
                            </li>
                            <li className="shape-02 scene">
                                <img data-depth="-2.5" src="/assets/images/cta/shape-09.png" alt="shape" />
                            </li>
                            <li className="shape-03 scene">
                                <img data-depth="-2" src="/assets/images/cta/shape-08.png" alt="shape" />
                            </li>
                            <li className="shape-04 scene">
                                <img data-depth="2" src="/assets/images/about/shape-13.png" alt="shape" />
                            </li>
                        </ul>
                    </div>
                </div>
            </div><br /><br />
            {/* compagnies */}

            <BrandArea />
        </div>
    )
}

export default CompaniesStudentsWorkedContent
