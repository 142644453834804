
import React from 'react'

import Content404 from '../../components/pages/Error404/Content404';

function Error404 () {
  return (
    <div>
      
        <Content404/>
        
    </div>
  )
}

export default Error404
