import React from 'react'
import { Link } from 'react-router-dom'

function Content400() {
  return (
    <div>
{/*         
        <div className="edu-breadcrumb-area">
            <div className="container">
                <div className="breadcrumb-inner">
                    <div className="page-title">
                        <h1 className="title">Error-404</h1>
                    </div>
                    <ul className="edu-breadcrumb">
                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                        <li className="separator"><i className="icon-angle-right"></i></li>
                        
                        <li className="separator"><i className="icon-angle-right"></i></li>
                        <li className="breadcrumb-item active" aria-current="page">Error-404</li>
                    </ul>
                </div>
            </div>
            <ul className="shape-group">
                <li className="shape-1">
                    <span></span>
                </li>
                <li className="shape-2 scene"><img data-depth="2" src="assets/images/about/shape-13.png" alt="shape"/></li>
                <li className="shape-3 scene"><img data-depth="-2" src="assets/images/about/shape-15.png" alt="shape"/></li>
                <li className="shape-4">
                    <span></span>
                </li>
                <li className="shape-5 scene"><img data-depth="2" src="assets/images/about/shape-07.png" alt="shape"/></li>
            </ul>
        </div> */}
        {/* <!--=====================================-->
        <!--=        404 Area Start            =-->
        <!--=====================================--> */}
        <section className="section-gap-equal error-page-area">
            <div className="container">
                <div className="edu-error">
                    <div className="thumbnail">
                        <img src="assets/images/others/404-imag.png" alt="404 Error"/> 
                
                        <ul className="shape-group">
                            
                            {/* <li className="shape-1 scene">
                                <img data-depth="2" src="assets/images/about/shape-25.png" alt="Shape"/>

                            </li>
                            <li className="shape-2 scene">
                                <img data-depth="-2" src="assets/images/about/shape-15.png" alt="Shape"/>
                            </li> */}
                            {/* <li className="shape-3 scene">
                                <img data-depth="2" src="assets/images/about/shape-13.png" alt="Shape"/>
                            </li>
                            <li className="shape-4 scene">
                                <img data-depth="-2" src="assets/images/counterup/shape-02.png" alt="Shape"/>
                            </li> */}
                        </ul>
                    </div>
                    <div className="content">
                        <h2 className="title">Looks like you got lost!</h2>
                        <h4 className="subtitle">TWe couldn't find the page you were looking for.</h4>
                        <Link to="/" className="edu-btn"><i className="icon-west"></i>Back to Homepage</Link>
                    </div>
                </div>
            </div>
            <ul className="shape-group">
                <li className="shape-1">
                    <img src="assets/images/others/map-shape-2.png" alt="Shape"/>
                </li>
                <li className="shape-3 scene">
                                <img data-depth="2" src="assets/images/about/shape-13.png" alt="Shape"/>
                            </li>
                            <li className="shape-4 scene">
                                <img data-depth="-2" src="assets/images/counterup/shape-02.png" alt="Shape"/>
                            </li>
            </ul>
        </section>
    </div>
  )
}

export default Content400
