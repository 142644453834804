import React from 'react'
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <footer className="edu-footer footer-lighten bg-image footer-style-1">
        <div className="footer-top">
                <div className="container">
                    <div className="row g-5">
                        <div className="col-lg-3 col-md-6">
                            <div className="edu-footer-widget">
                                <div className="logo">
                                    <Link to="/">
                                        <img className="logo-light" src="/assets/images/logo/logo-dark.png" alt="Corporate Logo"/>
                                        <img className="logo-dark" src="/assets/images/logo/logo-white.png" alt="Corporate Logo"/>
                                    </Link>
                                </div>
                                <p className="description">
                                    Welcome to the Linux and DevOps system administration training program. <br/>
                                    {/* Some of you may be asking the question what is Linux?  Well it’s normal to ask if you are not an IT professional.  */}
                                </p>
                                <div className="widget-information">
                                    <ul className="information-list">
                                        <li><span>Add:</span>7070 Knights Ct, Missouri City TX 77459-5225</li>
                                        <li><span>Call:</span><Link to="tel:+011235641231">+1 (302) 689 3440</Link></li>
                                        <li><span>Email:</span><Link to="mailto:contact@utrains.org" target="_blank">contact@utrains.org</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6">
                            <div className="edu-footer-widget explore-widget">
                                <h4 className="widget-title">Online Platform</h4>
                                <div className="inner">
                                    <ul className="footer-link link-hover">
                                        <li><Link to="/about">About</Link></li>
                                        <li><Link to="/course_one">Courses</Link></li>
                                        <li><Link to="/team_one">Instructor</Link></li>
                                        <li><Link to="/event_grid">Events</Link></li>
                                        <li><Link to="/team_details">Instructor Profile</Link></li>
                                        <li><Link to="/purchase_guide">Purchase Guide</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2 col-sm-6">
                            <div className="edu-footer-widget quick-link-widget">
                                <h4 className="widget-title">Links</h4>
                                <div className="inner">
                                    <ul className="footer-link link-hover">
                                        <li><Link to="/contact_us">Contact Us</Link></li>
                                        <li><Link to="/gallery_grid">Gallery</Link></li>
                                        <li><Link to="/blog_standard">News & Articles</Link></li>
                                        <li><Link to="/faq">FAQ's</Link></li>
                                        <li><Link to="/my_account">Sign In/Registration</Link></li>
                                        <li><Link to="/coming_soon">Coming Soon</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="edu-footer-widget">
                                <h4 className="widget-title">Contacts</h4>
                                <div className="inner">
                                    <p className="description">Enter your email address to register to our newsletter subscription</p>
                                    <div className="input-group footer-subscription-form">
                                        <input type="email" className="form-control" placeholder="Your email"/>
                                        <button className="edu-btn btn-medium" type="button">Subscribe <i className="icon-4"></i></button>
                                    </div>
                                    <ul className="social-share icon-transparent">
                                        <li><Link to="#" className="color-fb"><i className="icon-facebook"></i></Link></li>
                                        <li><Link to="#" className="color-linkd"><i className="icon-linkedin2"></i></Link></li>
                                        <li><Link to="#" className="color-ig"><i className="icon-instagram"></i></Link></li>
                                        <li><Link to="#" className="color-twitter"><i className="icon-twitter"></i></Link></li>
                                        <li><Link to="#" className="color-yt"><i className="icon-youtube"></i></Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
        <div className="copyright-area">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="inner text-center">
                            <p>Copyright 2021 <Link to="https://www.utrains.org" target="_blank">Utrains Team</Link> All Rights Reserved</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </footer>
  )
}

export default Footer
