import React from 'react';
import Accordion1 from './Accordion1';

const FAQAccordion2 = () => {
  const faqItems = [
    {
      title: 'What does it take become an author?',
      content: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt labore et dolore magna aliqua enim ad minim veniam quis nostrud exercitation ullamco qui laboris nis aliquip commodo consequat.'
    },
    {
      title: 'How do I find a school where I want to study?',
      content: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt labore et dolore magna aliqua enim ad minim veniam quis nostrud exercitation ullamco qui laboris nis aliquip commodo consequat.'
    },
    {
      title: 'Where should I study abroad?',
      content: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt labore et dolore magna aliqua enim ad minim veniam quis nostrud exercitation ullamco qui laboris nis aliquip commodo consequat.'
    },
    {
      title: 'Where can I find information on private companies?',
      content: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt labore et dolore magna aliqua enim ad minim veniam quis nostrud exercitation ullamco qui laboris nis aliquip commodo consequat.'
    }
  ];

  return (
    <div className="tab-pane fade" id="ad-ques" role="tabpanel">
      <div className="faq-accordion">
        <Accordion1 items={faqItems} />
      </div>
    </div>
  );
};

export default FAQAccordion2;