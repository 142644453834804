import React from 'react'


import NextSessionContent from '../../components/pages/Next-session/NextSessionContent'

function SessionDetails() {
  return (
  
     
        <NextSessionContent/>
       
  )
}

export default SessionDetails
