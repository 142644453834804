import React from 'react'

function ContactForm() {
  return (
    <div className="contact-form form-style-2">
      <div className="section-title">
        <h4 className="title">Get In Touch</h4>
        <p>Please get in touch and we'll be glad to help.</p>
      </div>
      <form className="rnt-contact-form rwt-dynamic-form" id="contact-form" method="POST" action="mail.php">
        <div className="row row--10">
          <div className="form-group col-12">
            <input type="text" name="contact-name" id="contact-name" placeholder="Your name" />
          </div>
          <div className="form-group col-12">
            <input type="email" name="contact-email" id="contact-email" placeholder="Enter your email" />
          </div>
          <div className="form-group col-12">
            <input type="tel" name="contact-phone" id="contact-phone" placeholder="Phone number" />
          </div>
          <div className="form-group col-12">
            <textarea name="contact-message" id="contact-message" cols="30" rows="4" placeholder="Your message"></textarea>
          </div>
          <div className="form-group col-12">
            <button className="rn-btn edu-btn btn-medium submit-btn" name="submit" type="submit">Submit Message <i className="icon-4"></i></button>
          </div>
        </div>
      </form>
      <ul className="shape-group">
        <li className="shape-1 scene"><img data-depth="1" src="assets/images/about/shape-13.png" alt="Shape" /></li>
        <li className="shape-2 scene"><img data-depth="-1" src="assets/images/counterup/shape-02.png" alt="Shape" /></li>
      </ul>
    </div>
  )
}

export default ContactForm