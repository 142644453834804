
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PaginatedTable from './PaginatedTable';

class TestimonialTable3 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      pageSize: 15,
      totalRows: 25,
      data: [
        { name: 'Jonathan', jobTitle: 'Lead Devops Engineer', salary: '160k/year' },
        { name: 'Sam', jobTitle: 'Sr DevOps Engineer', salary: '100k/year' },
        { name: 'Salome', jobTitle: 'Sr DevOps Engineer', salary: '110k/year' },
        { name: 'Olivia', jobTitle: 'SRE/DevOps Engineer', salary: '135.2k/year' },
        { name: 'Annie', jobTitle: 'Lead Analyst Engineer', salary: '155k/year' },
        { name: 'Eric', jobTitle: 'Sr Devops Consultant', salary: '135k/year' },
        { name: 'Serge', jobTitle: 'Sr Devops Consultant', salary: '155k/year' },
        { name: 'Marcel', jobTitle: 'Sr DevOps Engineer', salary: '130k/year' },
        { name: 'Miranda', jobTitle: 'Sr Scrum Master', salary: '130k/year' },
        { name: 'Ritah', jobTitle: 'Onboarding Engineer', salary: '120k/year' },
        { name: 'Amoros', jobTitle: 'Operation Production Analyst', salary: '131k/year' },
        { name: 'Elly', jobTitle: 'Technical DevOps Consultant', salary: '120k/year' },
        { name: 'Lateef', jobTitle: 'Sr DevOps Engineer', salary: '135k/year' },
        { name: 'Lydie', jobTitle: 'Sr DevOps Engineer', salary: '115k/year' },
        { name: 'Ebini', jobTitle: 'Sr DevOps Engineer', salary: '125k/year' },
        { name: 'Jallah', jobTitle: 'Sr Devops Consultant', salary: '120k/year' },
        { name: 'Farhia', jobTitle: 'Sr Devops Consultant', salary: '120k/year' },
        { name: 'Jah', jobTitle: 'Sr Engineer', salary: '135k/year' },
        { name: 'Benjamin', jobTitle: 'Sr DevOps Engineer', salary: '130k/year' },
        { name: 'Batossa', jobTitle: 'Sr DevOps Engineer', salary: '120k/year' },
        { name: 'George', jobTitle: 'Sr DevOps Engineer', salary: '120k/year' },
        { name: 'Adetayo', jobTitle: 'System Administrator', salary: '100k/year' }
      ],
    };
  }

  nextPage = () => {
    const { currentPage, pageSize, totalRows } = this.state;
    const maxPage = Math.ceil(totalRows / pageSize);
    if (currentPage < maxPage) {
      this.setState((prevState) => ({
        currentPage: prevState.currentPage + 1,
      }));
    }
  };

  previousPage = () => {
    const { currentPage } = this.state;
    if (currentPage > 1) {
      this.setState((prevState) => ({
        currentPage: prevState.currentPage - 1,
      }));
    }
  };

  // render() {
  //   const { currentPage, pageSize, totalRows, data } = this.state;
  //   const startIndex = (currentPage - 1) * pageSize;
  //   const endIndex = startIndex + pageSize;
  //   const visibleData = data.slice(startIndex, endIndex);
  //   const showPreviousLink = currentPage > 1;
  //   const showNextLink = endIndex < totalRows;

  //   return (
  //     <div>
  //       <table id="customers">
  //         <thead>
  //           <tr>
  //             <th>Names</th>
  //             <th>Job title</th>
  //             <th>Salary per year</th>
  //           </tr>
  //         </thead>
  //         <tbody>
  //           {visibleData.map((row, index) => (
  //             <tr key={index}>
  //               <td>{row.name}</td>
  //               <td>{row.jobTitle}</td>
  //               <td>{row.salary}</td>
  //             </tr>
  //           ))}
  //         </tbody>
  //       </table>
  //       <ul className="edu-pagination">
  //         {showPreviousLink && (
  //           <li>
  //             <Link to="#" onClick={this.previousPage} aria-label="Previous">
  //               <i className="icon-west"></i>
  //             </Link >
  //           </li>
  //         )}
  //         <li className="active">
  //           <Link to="#">{currentPage}</Link>
  //         </li>
  //         {showNextLink && (
  //           <li>
  //             <Link to="#" onClick={this.nextPage}>{currentPage + 1}</Link>
  //           </li>
  //         )}
  //         <li className="more-next">
  //           <Link to="#"></Link >
  //         </li>
  //         <li>
  //           <Link to="#">{Math.ceil(totalRows / pageSize)}</Link >
  //         </li>
  //         <li>
  //           <Link to="#" onClick={this.nextPage} aria-label="Next">
  //             <i className="icon-east"></i>
  //           </Link>
  //         </li>
  //       </ul>
  //     </div>
  //   );
  // }
  render() {
    const { currentPage, pageSize, totalRows, data } = this.state;
    const showPreviousLink = currentPage > 1;
    const showNextLink = (currentPage - 1) * pageSize + 15 < totalRows;
  
    return (
      <PaginatedTable
        currentPage={currentPage}
        pageSize={pageSize}
        totalRows={totalRows}
        data={data}
        showPreviousLink={showPreviousLink}
        showNextLink={showNextLink}
        previousPage={this.previousPage}
        nextPage={this.nextPage}
      />
    );
  }
}

export default TestimonialTable3;

