import React from 'react'


import TestimonialContent from '../../components/pages/Testimonial/TestimonialContent';

function Testimonial() {
  return (
   
      <TestimonialContent/>
      
      
  )
}

export default Testimonial
