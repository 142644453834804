import React from 'react'
import { Link } from 'react-router-dom'
function SessionAllDetail() {
  return (
    <div className="tab-content" id="myTabContent">
        <div className="tab-pane fade show active" id="installment" role="tabpanel" aria-labelledby="overview-tab">
            <div className="row course-tab-content">
                <div className="col-lg-4 next-session-pricing-table-custom" data-sal-delay="100" data-sal="slide-up" data-sal-duration="800">
                    <div className="pricing-table">
                        <div className="pricing-header">
                            <h6 className="title">Silver Plan</h6>
                            <h6>6 installments</h6>
                            <div className="price-wrap">
                                <span className="amount">$3500</span>
                            </div>
                        </div>
                        <div className="pricing-body">
                            <ul className="list-item">
                                <li className="ns-info"><i className="icon-20"></i>$584 Per month</li>
                                <li className="ns-info"><i className="icon-20"></i>Pay in 6 months</li>
                                <li className="ns-info"><i className="icon-20"></i>$584 due now</li>
                                <li className="ns-info"><i className="icon-20"></i>$584/mo* for 5 more months</li>
                            </ul>
                        </div>
                        <div className="pricing-btn">
                            <Link className="edu-btn btn-border btn-medium" to="/checkout">Select plan<i className="icon-east"></i></Link>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 next-session-pricing-table-custom" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                    <div className="pricing-table">
                        <div className="pricing-header">
                            <h6 className="title">Gold Plan</h6>
                            <h6>3 installments</h6>
                            <div className="price-wrap">
                                <span className="amount">$3300</span>
                            </div>
                        </div>
                        <div className="pricing-body">
                            <ul className="list-item">
                                <li className="ns-info"><i className="icon-20"></i>Save $200</li>
                                <li className="ns-info"><i className="icon-20"></i>$1100 due now</li>
                                <li className="ns-info"><i className="icon-20"></i>$1100/Mo* for the first 3 months</li>
                                <li className="ns-info"><i className="icon-20"></i>$1100/mo* for 2 more months</li>
                            </ul>
                        </div>
                        <div className="pricing-btn">
                            <Link className="edu-btn btn-border btn-medium active" to="/checkout">Select plan<i className="icon-east"></i></Link>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 next-session-pricing-table-custom" data-sal-delay="200" data-sal="slide-up" data-sal-duration="800">
                    <div className="pricing-table">
                        <div className="pricing-header">
                            <h6 className="title">Diamond Plan</h6>
                            <h6 className="title">Pay In Full</h6>
                            <div className="price-wrap">
                                <span className="amount">$3000</span>
                            </div>
                        </div>
                        <div className="pricing-body">
                            <ul className="list-item">
                                <li className="ns-info"><i className="icon-20"></i>Save $500</li>
                                <li className="ns-info"><i className="icon-20"></i>$3000 due now</li>
                                <li className="ns-info"><i className="icon-20"></i>Pay in one time​</li>
                                <li className="ns-info"><i className="icon-20"></i>No recurrent billing</li>
                            </ul>
                        </div>
                        <div className="pricing-btn">
                            <Link className="edu-btn btn-border btn-medium" to="/checkout">Select plan<i className="icon-east"></i></Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="tab-pane fade" id="overview" role="tabpanel" aria-labelledby="overview-tab">
            <div className="course-tab-content">
                <div className="course-overview">
                    <h3 className="heading-title">Course Description</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor inc idid unt ut labore et dolore magna aliqua enim ad minim veniam, quis nostrud exerec tation ullamco laboris nis aliquip commodo consequat duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur enim ipsam.</p>
                    <p className="mb--60">Excepteur sint occaecat cupidatat non proident sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium totam rem aperiam.</p>
                    <h5 className="title">What You’ll Learn?</h5>
                    <ul className="mb--60">
                        <li>Learn to use Python professionally, learning both Python 2 & Python 3!</li>
                        <li>Build 6 beautiful real-world projects for your portfolio (not boring toy apps)</li>
                        <li>Understand the Theory behind Vue.js and use it in Real Projects</li>
                        <li>Create responsive, accessible, and beautiful layouts</li>
                    </ul>
                    <p>Consectetur adipisicing elit, sed do eiusmod tempor inc idid unt ut labore et dolore magna aliqua enim ad minim veniam quis nostrud exerec tation ullamco laboris nis aliquip commodo consequat duis aute irure dolor.</p>
                </div>
            </div>
        </div>
        <div className="tab-pane fade" id="carriculam" role="tabpanel" aria-labelledby="carriculam-tab">
            <div className="course-tab-content">
                <div className="course-curriculam">
                    <h3 className="heading-title">Course Curriculum</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor inc idid unt ut labore et dolore magna aliqua enim ad minim veniam, quis nostrud exerec tation ullamco laboris nis aliquip commodo consequat.</p>
                    <div className="course-lesson">
                        <h5 className="title">Week 1-4</h5>
                        <p>Advanced story telling techniques for writers: Personas, Characters & Plots</p>
                        <ul>
                            <li>
                                <div className="text"><i className="icon-65"></i> Introduction</div>
                                <div className="icon"><i className="icon-68"></i></div>
                            </li>
                            <li>
                                <div className="text"><i className="icon-65"></i> Course Overview</div>
                                <div className="icon"><i className="icon-68"></i></div>
                            </li>
                            <li>
                                <div className="text"><i className="icon-65"></i> Local Development Environment Tools</div>
                                <div className="badge-list">
                                    <span className="badge badge-primary">0 Question</span>
                                    <span className="badge badge-secondary">10 Minutes</span>
                                </div>
                            </li>
                            <li>
                                <div className="text"><i className="icon-65"></i> Course Exercise / Reference Files</div>
                                <div className="icon"><i className="icon-68"></i></div>
                            </li>
                            <li>
                                <div className="text"><i className="icon-65"></i> Code Editor Installation (Optional if you have one)</div>
                                <div className="icon"><i className="icon-68"></i></div>
                            </li>
                            <li>
                                <div className="text"><i className="icon-65"></i> Embedding PHP in HTML</div>
                                <div className="icon"><i className="icon-68"></i></div>
                            </li>
                        </ul>
                    </div>
                    <div className="course-lesson">
                        <h5 className="title">Week 5-8</h5>
                        <p>Advanced story telling techniques for writers: Personas, Characters & Plots</p>
                        <ul>
                            <li>
                                <div className="text"><i className="icon-65"></i> Defining Functions</div>
                                <div className="icon"><i className="icon-68"></i></div>
                            </li>
                            <li>
                                <div className="text"><i className="icon-65"></i>Function Parameters</div>
                                <div className="icon"><i className="icon-68"></i></div>
                            </li>
                            <li>
                                <div className="text"><i className="icon-65"></i> Return Values From Functions</div>
                                <div className="badge-list">
                                    <span className="badge badge-primary">0 Question</span>
                                    <span className="badge badge-secondary">10 Minutes</span>
                                </div>
                            </li>
                            <li>
                                <div className="text"><i className="icon-65"></i> Global Variable and Scope</div>
                                <div className="icon"><i className="icon-68"></i></div>
                            </li>
                            <li>
                                <div className="text"><i className="icon-65"></i>Newer Way of creating a Constant</div>
                                <div className="icon"><i className="icon-68"></i></div>
                            </li>
                            <li>
                                <div className="text"><i className="icon-65"></i> Constants</div>
                                <div className="icon"><i className="icon-68"></i></div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default SessionAllDetail
