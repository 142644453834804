import React from 'react';
import TestimonialHeader from './TestimonialHeader';
import TestimonialNavigation from './TestimonialNavigation';
import TestimonialCarousel from './TestimonialCarousel';

function TestimonialImage() {
  return (
    <div className="testimonial-area-4" style={{ padding: '40px 0 70px' }}>
      <div className="container edublink-animated-shape">
        <div className="row g-5">
          <div className="col-lg-5">
            <TestimonialHeader />
            <TestimonialNavigation />
          </div>
          <div className="col-lg-7">
            <TestimonialCarousel />
          </div>
        </div>
        <ul className="shape-group">
          <li className="shape-2 scene" data-sal-delay="1000" data-sal="fade" data-sal-duration="1000">
            <img data-depth="-1.5" src="assets/images/others/shape-34.png" alt="Shape" />
          </li>
        </ul>
      </div>
      <ul className="shape-group">
        <li className="shape-1" data-sal-delay="1000" data-sal="fade" data-sal-duration="1000">
          <img src="assets/images/others/shape-48.png" alt="Shape" />
        </li>
      </ul>
    </div>
  );
}

export default TestimonialImage;