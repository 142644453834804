import React from 'react'
import { Link } from 'react-router-dom'

function ContactInfo() {
  return (
    <div className="contact-us-info">
      <h3 className="heading-title">Interested in our program <br /> or need advice ?</h3>
      <ul className="address-list">
        <li>
          <h5 className="title">Address</h5>
          <p>7070 Knights Ct, Missouri City TX 77459-5225</p>
        </li>
        <li>
          <h5 className="title">Email</h5>
          <p><Link to="mailto:contact@utrains.org">contact@utrains.org</Link></p>
        </li>
        <li>
          <h5 className="title">Phone</h5>
          <p><Link to="tel:+13026893440">+1 (302) 689 3440</Link></p>
        </li>
      </ul>
      <ul className="social-share">
        <li><Link to="#"><i className="icon-share-alt"></i></Link></li>
        <li><Link to="#"><i className="icon-facebook"></i></Link></li>
        <li><Link to="#"><i className="icon-twitter"></i></Link></li>
        <li><Link to="#"><i className="icon-linkedin2"></i></Link></li>
      </ul>
    </div>
  )
}

export default ContactInfo