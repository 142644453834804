import React from 'react';

const BrandGrid = ({ imgSrc, altText }) => {
  return (
    <div className="brand-grid">
      <img src={imgSrc} alt={altText} />
    </div>
  );
};

export default BrandGrid;