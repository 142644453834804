import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PaginatedTable from './PaginatedTable';

class TestimonialTable1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      pageSize: 15,
      totalRows: 30,
      data: [
        { name: "Aline", jobTitle: "Cloud Engineer", salary: "110k/year" },
        { name: "Walter", jobTitle: "AWS Devops Specialist", salary: "100k/year" },
        { name: "Laure", jobTitle: "Cloud Engineer", salary: "140k/year" },
        { name: "Ariel", jobTitle: "Kubernetes Devops Engineer", salary: "120k/year" },
        { name: "Ritah", jobTitle: "Onboarding Engineer", salary: "86k/year" },
        { name: "Yannick", jobTitle: "Sr Cloud Engineer", salary: "115k/year" },
        { name: "Tina", jobTitle: "Sr Cloud Engineer", salary: "130k+5k sign on" },
        { name: "Augustine", jobTitle: "Technology Lead", salary: "108k/year" },
        { name: "Elvis", jobTitle: "AWS DevOps Engineer", salary: "130k/year" },
        { name: "Arthur", jobTitle: "AWS DevOps Engineer", salary: "135k/year" },
        { name: "Yinka", jobTitle: "DevSecOps Engineer", salary: "120k/year" },
        { name: "Paul", jobTitle: "Sr Cloud Op Engineer", salary: "131k/year" },
        { name: "Kenneth", jobTitle: "Cloud tech Engineer", salary: "125k/year" },
        { name: "Elvin", jobTitle: "IT consultant", salary: "85k/year" },
        { name: "Eric", jobTitle: "Solution specialist", salary: "95k/year" },
        { name: "Herebert", jobTitle: "AWS Devops Specialist", salary: "110k/year" },
        { name: "Joseph", jobTitle: "Cloud Engineer", salary: "90k/year" },
        { name: "Dominique", jobTitle: "Cloud Engineer", salary: "100k/year" },
        { name: "Eric", jobTitle: "Cloud Engineer", salary: "120k/year" },
        { name: "Nahoumou", jobTitle: "Cloud Engineer", salary: "105k/year" },
        { name: "George", jobTitle: "Cloud Engineer", salary: "130k/year" },
        { name: "Nicholas", jobTitle: "Cloud Engineer", salary: "100k/year" },
        { name: "Genevieve", jobTitle: "Cloud Devops Engineer", salary: "140k/year" },
        { name: "Amy", jobTitle: "AWS Cloud System Engineer", salary: "100k/year" },
        { name: "Clement", jobTitle: "Cloud Support Engineer", salary: "145k/year" },
        { name: "Farhia", jobTitle: "Senior Cloud Engineer", salary: "135k/year" },
        { name: "Gael", jobTitle: "Cloud DevOps Software Engineer", salary: "130k/year" },
        { name: "Yvan", jobTitle: "Cloud Platform Engineer", salary: "120k/year" },
        { name: "Ayukncha", jobTitle: "AWS Cloud System Administrator", salary: "90k/year" }
      ],
    };
  }

  nextPage = () => {
    const { currentPage, pageSize, totalRows } = this.state;
    const maxPage = Math.ceil(totalRows / pageSize);
    if (currentPage < maxPage) {
      this.setState((prevState) => ({
        currentPage: prevState.currentPage + 1,
      }));
    }
  };

  previousPage = () => {
    const { currentPage } = this.state;
    if (currentPage > 1) {
      this.setState((prevState) => ({
        currentPage: prevState.currentPage - 1,
      }));
    }
  };

  // render() {
  //   const { currentPage, pageSize, totalRows, data } = this.state;
  //   const startIndex = (currentPage - 1) * pageSize;
  //   const endIndex = startIndex + pageSize;
  //   const visibleData = data.slice(startIndex, endIndex);
  //   const showPreviousLink = currentPage > 1;
  //   const showNextLink = endIndex < totalRows;

  //   return (
  //     <div>
  //       <table id="customers">
  //         <thead>
  //           <tr>
  //             <th>Names</th>
  //             <th>Job title</th>
  //             <th>Salary per year</th>
  //           </tr>
  //         </thead>
  //         <tbody>
  //           {visibleData.map((row, index) => (
  //             <tr key={index}>
  //               <td>{row.name}</td>
  //               <td>{row.jobTitle}</td>
  //               <td>{row.salary}</td>
  //             </tr>
  //           ))}
  //         </tbody>
  //       </table>
  //       <ul className="edu-pagination">
  //         {showPreviousLink && (
  //           <li>
  //             <Link to="#" onClick={this.previousPage} aria-label="Previous">
  //               <i className="icon-west"></i>
  //             </Link >
  //           </li>
  //         )}
  //         <li className="active">
  //           <Link to="#">{currentPage}</Link>
  //         </li>
  //         {showNextLink && (
  //           <li>
  //             <Link to="#" onClick={this.nextPage}>{currentPage + 1}</Link>
  //           </li>
  //         )}
  //         <li className="more-next">
  //           <Link to="#"></Link >
  //         </li>
  //         <li>
  //           <Link to="#">{Math.ceil(totalRows / pageSize)}</Link >
  //         </li>
  //         <li>
  //           <Link to="#" onClick={this.nextPage} aria-label="Next">
  //             <i className="icon-east"></i>
  //           </Link>
  //         </li>
  //       </ul>
  //     </div>
  //   );
  // }
  render() {
    const { currentPage, pageSize, totalRows, data } = this.state;
    const showPreviousLink = currentPage > 1;
    const showNextLink = (currentPage - 1) * pageSize + 15 < totalRows;
  
    return (
      <PaginatedTable
        currentPage={currentPage}
        pageSize={pageSize}
        totalRows={totalRows}
        data={data}
        showPreviousLink={showPreviousLink}
        showNextLink={showNextLink}
        previousPage={this.previousPage}
        nextPage={this.nextPage}
      />
    );
  }
}

export default TestimonialTable1;
