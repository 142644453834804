import React from 'react'
// import { Link } from 'react-router-dom'

function SupportContent() {
  return (
    <div>
        {/* <!--=====================================-->
        <!--=       Breadcrumb Area Start      =-->
        <!--=====================================--> */}
       
        <section className="contact-us-area bk-check-contact-us-area-custom">
            <div className="testimonial-area-19 bk-check-testimonial-area-19-custom">
                <div className="container edublink-animated-shape">
                    <div className="row g-lg-5">
                        <div className="col-lg-5 edu-order-md-2">
                            <div className="testimonial-bg-area">
                                <img src="/assets/images/bg/bg-image-55.webp" alt=""/>
                            </div>
                        </div>
                        <div className="col-lg-7 edu-order-md-1">
                            <div>
                                <div className="contact-form form-style-2 bk-check-form-style-custom">
                                    <div className="section-title backgroundcheck-gap-top">
                                    <h3 className="title">SUBMIT A TICKET</h3>
                                <span >If you need assistance for.</span><br/>
                            <span className="shape-line"><i className="icon-19"></i></span><br/>
                            <h6 className="heading-title">Job interview, Job technical support, Tuition and Subscription, Technical issue with class material.</h6>
                                    </div>
                                    <form className="rnt-contact-form rwt-dynamic-form" id="contact-form" method="POST" action="mail.php">
                        
                     
                    <div className="row row--10">
                        <div className="form-group col-12">
                 
                            <input type="text" name="contact-name" id="contact-name" placeholder="Your Name"/>
                        </div>
                        
                       
                        <div className="form-group col-12">
                      
                            <input type="text" name="subject" id="subject" placeholder="Subject"/>
                        </div>
                     
                        
                        <div className="form-group col-12">
                   
                            <input type="tel" name="contact-phone" id="contact-phone" placeholder="Department"/>
                        </div>
                       

                       
                        <div className="form-group col-12">
              
                            <textarea name="contact-message" id="contact-message" placeholder=" your message"></textarea>
                        </div>
                       
                       
                        <div className="form-group col-12">
                            <h4>Attachments</h4>
                            <p>You can upload multiple files (maximum 2 MB each) of the following types: .jpg, .jpeg, .png, .gif, .pdf, .doc, .docx, .ppt, .pptx, .pps, .ppsx, .odt, .xls, .xlsx, .mp3, .m4a, .ogg, .wav, .mp4, .m4v, .mov, .wmv, .avi, .mpg, .ogv, .3gp, .3g2, .zip</p>
                            <input type="file" name="attachment-file" id="attachment-file" multiple />
                        </div>
                     
                        <div className="form-group col-12">
                                        <button className="rn-btn edu-btn btn-medium submit-btn" name="submit" type="submit">Submit Message <i className="icon-4"></i></button>
                                    </div>
                                    </div>
                             </form>
                            <ul className="shape-group">
                                <li className="shape-1 scene"><img data-depth="1" src="assets/images/about/shape-13.png" alt="Shape"/></li>
                                <li className="shape-2 scene"><img data-depth="-1" src="assets/images/counterup/shape-02.png" alt="Shape"/></li>
                            </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
  )
}

export default SupportContent
