// FeedbackForm.js
import React, { useState } from 'react';

const FeedbackForm = ({ title, description, options, onSubmit }) => {
  const [email, setEmail] = useState('');
  const [selectedOption, setSelectedOption] = useState(null);
  const [feedback, setFeedback] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({ email, selectedOption, feedback });
  };

  return (
    <div className="contact-form form-style-2">
      <div className="section-title">
        <h4 className="title">{title}</h4>
        <p>{description}</p>
      </div>
      <form className="rnt-contact-form rwt-dynamic-form" onSubmit={handleSubmit}>
        <div className="row row--10">
          <div className="form-group col-12">
            <input
              type="email"
              name="contact-email"
              id="contact-email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="form-group col-12">
            <p className="form-group" style={{ margin: '30px 0' }}>
              Select the field in which you want to make a suggestion
            </p>
            {options.map((option, index) => (
              <div key={index} className="edu-form-check">
                <input
                  type="radio"
                  id={`suggestion-feedback${index + 1}`}
                  name="suggestion-feedback"
                  checked={selectedOption === option}
                  onChange={() => setSelectedOption(option)}
                />
                <label htmlFor={`suggestion-feedback${index + 1}`}>{option}</label>
              </div>
            ))}
          </div>
          <div className="form-group col-12">
            <textarea
              name="contact-message"
              id="contact-message"
              cols="30"
              rows="2"
              placeholder="Your feedback"
              value={feedback}
              onChange={(e) => setFeedback(e.target.value)}
            ></textarea>
          </div>
          <div className="form-group col-12">
            <button className="rn-btn edu-btn btn-medium submit-btn" type="submit">
              Submit Message <i className="icon-4"></i>
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default FeedbackForm;