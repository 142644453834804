// FaqArea.js
import React from 'react';
import FeedbackForm from './FeedbackForm';

const FaqArea = () => {
  const handleFeedbackSubmit = (feedbackData) => {
    // Handle feedback submission, e.g. send to backend
    console.log(feedbackData);
  };

  return (
    <div className="edu-faq-area faq-style-1 faq-style-7">
      <div className="container">
        <div className="row g-5 row--45">
          <div className="col-lg-6 edu-order-md-2">
            <div className="edu-faq-gallery">
              <div
                className="faq-thumbnail thumbnail-1"
                data-sal-delay="50"
                data-sal="slide-right"
                data-sal-duration="800"
              >
                <img src="assets/images/others/faq-7.webp" alt="Faq Images" />
              </div>
              <ul className="shape-group">
                <li
                  className="shape-1"
                  data-sal-delay="500"
                  data-sal="fade"
                  data-sal-duration="200"
                >
                  <img src="assets/images/cta/shape-09.png" alt="Shape Images" />
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-6 edu-order-md-1">
            <FeedbackForm
              title="Feedback"
              description="We would love to know what you think about the program!"
              options={[
                'Training content (DevOps, Linux, aws, etc.)',
                'Web site design',
                'Tuition',
                'A new functionality (Suggest a new functionality for the website)',
              ]}
              onSubmit={handleFeedbackSubmit}
            />
          </div>
        </div>
      </div>
      <ul className="shape-group">
        <li
          className="shape-bg"
          data-sal-delay="500"
          data-sal="fade"
          data-sal-duration="200"
        >
          <img src="assets/images/bg/bg-image-36.svg" alt="Shape Images" />
        </li>
      </ul>
    </div>
  );
};

export default FaqArea;