
import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import PaginatedTable from './PaginatedTable';

class TestimonialTable5 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      pageSize: 15,
      totalRows: 15,
      data: [
        { name: 'Guerin', jobTitle: 'Sr System Engineer', salary: '110k/year' },
        { name: 'Terence', jobTitle: 'System Engineer', salary: '110k/year' },
        { name: 'Olujoke', jobTitle: 'Sr System Engineer', salary: '94k/year' },
        { name: 'Nancy', jobTitle: 'Linux Engineer', salary: '95k/year' },
        { name: 'Mokom', jobTitle: 'Linux System Engineer', salary: '125k/year' },
      ],
    };
  }

  nextPage = () => {
    const { currentPage, pageSize, totalRows } = this.state;
    const maxPage = Math.ceil(totalRows / pageSize);
    if (currentPage < maxPage) {
      this.setState((prevState) => ({
        currentPage: prevState.currentPage + 1,
      }));
    }
  };

  previousPage = () => {
    const { currentPage } = this.state;
    if (currentPage > 1) {
      this.setState((prevState) => ({
        currentPage: prevState.currentPage - 1,
      }));
    }
  };

  // render() {
  //   const { currentPage, pageSize, totalRows, data } = this.state;
  //   const startIndex = (currentPage - 1) * pageSize;
  //   const endIndex = startIndex + pageSize;
  //   const visibleData = data.slice(startIndex, endIndex);
  //   const showPreviousLink = currentPage > 1;
  //   const showNextLink = endIndex < totalRows;

  //   return (
  //     <div>
  //       <table id="customers">
  //         <thead>
  //           <tr>
  //             <th>Names</th>
  //             <th>Job title</th>
  //             <th>Salary per year</th>
  //           </tr>
  //         </thead>
  //         <tbody>
  //           {visibleData.map((row, index) => (
  //             <tr key={index}>
  //               <td>{row.name}</td>
  //               <td>{row.jobTitle}</td>
  //               <td>{row.salary}</td>
  //             </tr>
  //           ))}
  //         </tbody>
  //       </table>
  //       <ul className="edu-pagination">
  //         {showPreviousLink && (
  //           <li>
  //             <Link to="#" onClick={this.previousPage} aria-label="Previous">
  //               <i className="icon-west"></i>
  //             </Link >
  //           </li>
  //         )}
  //         <li className="active">
  //           <Link to="#">{currentPage}</Link>
  //         </li>
  //         {showNextLink && (
  //           <li>
  //             <Link to="#" onClick={this.nextPage}>{currentPage + 1}</Link>
  //           </li>
  //         )}
  //         <li className="more-next">
  //           <Link to="#"></Link >
  //         </li>
  //         <li>
  //           <Link to="#">{Math.ceil(totalRows / pageSize)}</Link >
  //         </li>
  //         <li>
  //           <Link to="#" onClick={this.nextPage} aria-label="Next">
  //             <i className="icon-east"></i>
  //           </Link>
  //         </li>
  //       </ul>
  //     </div>
  //   );
  // }
  render() {
    const { currentPage, pageSize, totalRows, data } = this.state;
    const showPreviousLink = currentPage > 1;
    const showNextLink = (currentPage - 1) * pageSize + 15 < totalRows;
  
    return (
      <PaginatedTable
        currentPage={currentPage}
        pageSize={pageSize}
        totalRows={totalRows}
        data={data}
        showPreviousLink={showPreviousLink}
        showNextLink={showNextLink}
        previousPage={this.previousPage}
        nextPage={this.nextPage}
      />
    );
  }
}

export default TestimonialTable5;

