import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class PaginatedTable extends Component {
  render() {
    const { currentPage, pageSize, totalRows, data, showPreviousLink, showNextLink, previousPage, nextPage } = this.props;
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    const visibleData = data.slice(startIndex, endIndex);

    return (
      <div>
        <table id="customers">
          <thead>
            <tr>
              <th>Names</th>
              <th>Job title</th>
              <th>Salary per year</th>
            </tr>
          </thead>
          <tbody>
            {visibleData.map((row, index) => (
              <tr key={index}>
                <td>{row.name}</td>
                <td>{row.jobTitle}</td>
                <td>{row.salary}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <ul className="edu-pagination">
          {showPreviousLink && (
            <li>
              <Link to="#" onClick={previousPage} aria-label="Previous">
                <i className="icon-west"></i>
              </Link>
            </li>
          )}
          <li className="active">
            <Link to="#">{currentPage}</Link>
          </li>
          {showNextLink && (
            <li>
              <Link to="#" onClick={nextPage}>{currentPage + 1}</Link>
            </li>
          )}
          <li className="more-next">
            <Link to="#"></Link>
          </li>
          <li>
            <Link to="#">{Math.ceil(totalRows / pageSize)}</Link>
          </li>
          <li>
            <Link to="#" onClick={nextPage} aria-label="Next">
              <i className="icon-east"></i>
            </Link>
          </li>
        </ul>
      </div>
    );
  }
}

export default PaginatedTable;