import React from 'react'
import { Link } from 'react-router-dom'
import BannerSection from './BannerSection'

function Side() {
  return (
    <div>
        <BannerSection/>

        {/* <!--=====================================-->
        <!--=       Features Area Start      =-->
        <!--=====================================-->

        <!-- Start Categories Area  --> */}
        <div className="features-area-2">
            <div className="container">
                <div className="features-grid-wrap">
                    <div className="features-box features-style-2 edublink-svg-animate">
                        <div className="icon">
                            <img className="svgInject" src="/assets/images/animated-svg-icons/online-class.svg" alt="animated icon"/>
                        </div>
                        <div className="content">
                            <h5 className="title"><span>3020</span> Online Courses</h5>
                        </div>
                    </div>
                    <div className="features-box features-style-2 edublink-svg-animate">
                        <div className="icon">
                            <img className="svgInject" src="/assets/images/animated-svg-icons/instructor.svg" alt="animated icon"/>
                        </div>
                        <div className="content">
                            <h5 className="title"><span>Top</span>Instructors</h5>
                        </div>
                    </div>
                    <div className="features-box features-style-2 edublink-svg-animate">
                        <div className="icon certificate">
                            <img className="svgInject" src="/assets/images/animated-svg-icons/certificate.svg" alt="animated icon"/>
                        </div>
                        <div className="content">
                            <h5 className="title"><span>Online</span>Certifications</h5>
                        </div>
                    </div>
                    <div className="features-box features-style-2 edublink-svg-animate">
                        <div className="icon">
                            <img className="svgInject" src="/assets/images/animated-svg-icons/user.svg" alt="animated icon"/>
                        </div>
                        <div className="content">
                            <h5 className="title"><span>6000</span>Members</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* <!-- End Categories Area  --> */}

        {/* <!--=====================================-->
        <!--=       Categories Area Start      =-->
        <!--=====================================-->
        <!-- Start Categories Area  --> */}
        <div className="edu-categorie-area categorie-area-2 edu-section-gap">
            <div className="container">
                <div className="section-title section-center" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                    <h2 className="title">Why Us ?</h2>
                    <span className="shape-line"><i className="icon-19"></i></span>
                    <p>It matters where you study. Utrains offer a unique combination of top-tier training, support and affordability.</p>
                </div>

                <div className="row g-5">
                    <div className="col-lg-4 col-md-6" data-sal-delay="50" data-sal="slide-up" data-sal-duration="800">
                        <div className="categorie-grid categorie-style-2 color-primary-style ">
                            <div className="icon">
                                <i className="icon-9"></i>
                            </div>
                            <div className="content">
                                <Link to="/course_one">
                                    <h5 className="title">One-on-one coaching</h5>
                                </Link>
                                <br/>We provide you with individualized guidance to help you overcome any challenges you may face.
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6" data-sal-delay="100" data-sal="slide-up" data-sal-duration="800">
                        <div className="categorie-grid categorie-style-2 color-secondary-style">
                            <div className="icon">
                                <i className="icon-10 art-design"></i>
                            </div>
                            <div className="content">
                                <Link to="/course_one">
                                    <h5 className="title">Flexible payment plans</h5>
                                </Link>
                                <br/> We offer payment plans to make training affordable. Also discounts are available for one time payment.
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                        <div className="categorie-grid categorie-style-2 color-extra01-style">
                            <div className="icon">
                                <i className="icon-11 personal-development"></i>
                            </div>
                            <div className="content">
                                <Link to="/course_one">
                                    <h5 className="title">From zero to hero</h5>
                                </Link>
                                <br/> We set you up for success in the IT industry. Top students can go from earning zero to six figures.
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6" data-sal-delay="50" data-sal="slide-up" data-sal-duration="800">
                        <div className="categorie-grid categorie-style-2 color-tertiary-style">
                            <div className="icon">
                                <i className="icon-12 health-fitness"></i>
                            </div>
                            <div className="content">
                                <Link to="/course_one">
                                    <h5 className="title">Job support</h5>
                                </Link>
                                <br/> Gain access to a wide range of job postings from top companies in your desired industry. We provide training along with interviews tips and resume workshops.
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6" data-sal-delay="100" data-sal="slide-up" data-sal-duration="800">
                        <div className="categorie-grid categorie-style-2 color-extra02-style">
                            <div className="icon">
                                <i className="icon-13 data-science"></i>
                            </div>
                            <div className="content">
                                <Link to="/course_one">
                                    <h5 className="title">Latest technology</h5>
                                </Link>
                                <br/> Train on in-demand software with courses constantly updated to new trends in the industry.
                            </div>
                        </div>
                    </div>

                    {/* <div className="col-lg-4 col-md-6" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                        <div className="categorie-grid categorie-style-2 color-extra03-style">
                            <div className="icon">
                                <i className="icon-14"></i>
                            </div>
                            <div className="content">
                                <Link to="/course_one">
                                    <h5 className="title">Marketing</h5>
                                </Link>
                            </div>
                        </div>
                    </div> */}

                    <div className="col-lg-4 col-md-6" data-sal-delay="50" data-sal="slide-up" data-sal-duration="800">
                        <div className="categorie-grid categorie-style-2 color-extra04-style">
                            <div className="icon">
                                <i className="icon-15"></i>
                            </div>
                            <div className="content">
                                <Link to="/course_one">
                                    <h5 className="title">24/7 Support</h5>
                                </Link>
                                <br/> We are committed to providing a supportive learning environment. We offer humain support at anytime. This is in addition to live sessions with experts during the week.
                            </div>
                        </div>
                    </div>

                    {/* <div className="col-lg-4 col-md-6" data-sal-delay="100" data-sal="slide-up" data-sal-duration="800">
                        <div className="categorie-grid categorie-style-2 color-extra05-style">
                            <div className="icon">
                                <i className="icon-16 computer-science"></i>
                            </div>
                            <div className="content">
                                <Link to="/course_one">
                                    <h5 className="title">Computer Science</h5>
                                </Link>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                        <div className="categorie-grid categorie-style-2 color-extra06-style">
                            <div className="icon">
                                <i className="icon-17 video-photography"></i>
                            </div>
                            <div className="content">
                                <Link to="/course_one">
                                    <h5 className="title">Video & Photography</h5>
                                </Link>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
        {/* <!-- End Categories Area  --> */}


        {/* <!--=====================================-->
        <!--=       WHy Us Area Start      	=-->
        <!--=====================================--> */}
        <div className="gap-bottom-equal edu-about-area about-style-1">
            <div className="container edublink-animated-shape">
                <div className="row g-5 align-items-center">
                    <div className="col-lg-6">
                        <div className="about-image-gallery video-prof-box">
                            <div className="thumb">
                                <img src="/assets/images/about/about-01-1.webp" alt="About img"/>
                                <a href="https://www.youtube.com/watch?v=PICj5tr9hcc" className="popup-icon video-popup-activation" id='video-popup-activation'>
                                    <i className="icon-18"></i>
                                </a>
                            </div>
                            
                            <div className="award-status bounce-slide">
                                <div className="inner">
                                    <div className="icon">
                                        <i className="icon-21"></i>
                                    </div>
                                    <div className="content">
                                        <h6 className="title">29+</h6>
                                        <span className="subtitle">Wonderful Awards</span>
                                    </div>
                                </div>
                            </div>
                            <ul className="shape-group">
                                <li className="shape-1 scene" data-sal-delay="500" data-sal="fade" data-sal-duration="200">
                                    <img data-depth="1" src="/assets/images/about/shape-36.png" alt="Shape"/>
                                </li>
                                <li className="shape-2 scene" data-sal-delay="500" data-sal="fade" data-sal-duration="200">
                                    <img data-depth="-1" src="/assets/images/about/shape-37.png" alt="Shape"/>
                                </li>
                                <li className="shape-3 scene" data-sal-delay="500" data-sal="fade" data-sal-duration="200">
                                    <img data-depth="1" src="/assets/images/about/shape-02.png" alt="Shape"/>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-6" data-sal-delay="150" data-sal="slide-left" data-sal-duration="800">
                        <div className="about-content">
                            <div className="section-title section-left">
                                <span className="pre-title">Why Us ?</span>
                                <h2 className="title">What is this <span className="color-secondary">training </span>about?</h2>
                                <span className="shape-line"><i className="icon-19"></i></span>
                                <p>Computers have become essential in our daily lives. More and more companies rely on engineers to develop various applications. However, few of those engineers have the necessary skills to make an application in development status to an application available online for end users. The main goal of this training is to teach you all the steps necessary to put an application online (deployment). With these skills, you will be able to join any development team anywhere in the world. Learn more by listening to the video on the left.</p>
                            </div>
                            <ul className="features-list">
                                <li>Expert Trainers</li>
                                <li>Online Remote Learning</li>
                                <li>Lifetime Access</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <ul className="shape-group">
                    <li className="shape-1 circle scene" data-sal-delay="500" data-sal="fade" data-sal-duration="200">
                        {/* <span data-depth="-2.3"></span> */}
                        <img data-depth="1.6" src="/assets/images/counterup/shape-07.png" alt="Shape"/>
                    </li>
                </ul>
            </div>
        </div>

        {/* <!--=====================================-->
        <!--=       Exparties Area Start        =-->
        <!--=====================================-->
        <!-- Start Brand Area  --> */}
        <div className="edu-expart-area expert-area-1 section-gap-equal">
            <div className="container edublink-animated-shape">
                <div className="section-title section-center" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                    <span className="pre-title color-secondary">Advantages</span>
                    <h2 className="title"><span className="text-gradient">What if you wanted </span> to pay other trainers <br/>  for each of these job skills?</h2>
                    <span className="shape-line"><i className="icon-19"></i></span>
                    <p>This is the list of computer IT skills and their corresponding fees if you choose <br/> to take them individually on other platforms. As a bonus, we coach you on how to <br/> successfully search for a job. All this at a very low cost.</p>
                </div>

                <div className="row g-5">
                    
                    <div className="col-lg-6 col-md-6">
                        <div className="expert-progressbar" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                            <div className="single-progressbar">
                                <p className="progress-title">Linux Administrator</p>
                                <div className="progressbar-one"></div>
                            </div>
                            <div className="single-progressbar">
                                <p className="progress-title">DevOps</p>
                                <div className="progressbar-two"></div>
                            </div>
                            <div className="single-progressbar">
                                <p className="progress-title">Cloud Engineer</p>
                                <div className="progressbar-three"></div>
                            </div>
                            <div className="single-progressbar">
                                <p className="progress-title">RedHat Exam Preparation</p>
                                <div className="progressbar-four"></div>
                            </div>
                            <div className="single-progressbar last-ber">
                                <p className="progress-title">Coaching for Job search</p>
                                <div className="progressbar-four"></div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-6 d-flex justify-content-center align-items-center">
                        <div className="expert-section-heading">
                            <div className="section-title section-left" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                                <p className="text-center fs-1 discount">Discount</p>
                                <h5 class="utrains-headline e-animated e-hide-highlightt d-flex justify-content-center">
                                    
                                    <span class="utrains-headline-plain-text utrains-headline-text-wrapper">Don't spend </span>
                                    <span class="utrains-headline-dynamic-wrapper utrains-headline-text-wrapper">
                                        <span class="utrains-headline-dynamic-text utrains-headline-text-active">&nbsp; &nbsp; $15,000 &nbsp; &nbsp;</span>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 150" preserveAspectRatio="none"><path d="M497.4,23.9C301.6,40,155.9,80.6,4,144.4"></path><path d="M14.1,27.6c204.5,20.3,393.8,74,467.3,111.7"></path></svg>
                                    </span>
                                    <span class="utrains-headline-plain-text utrains-headline-text-wrapper"> anymore!</span>
                                </h5>

                                <div class="text-center d-flex flex-column justify-content-center align-items-center">
                                    <div class="text-center mb-5" >
                                        Get a discount of more than&nbsp; &nbsp;<strong>80%</strong> and save <span style={{color: '#ff0000' }}>&nbsp; &nbsp;<strong>$12,000&nbsp; &nbsp;</strong></span>.
                                        Learn all these skills with only <strong><span style={{color: 'var(--color-primary)' }} >$3,000</span></strong>
                                    </div>
                                    <Link to="/sessions/next" className="edu-btn btn-large" style={{ width: "fit-content"}}>Join Our Free Masterclass <i className="icon-4"></i></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* <!-- End Brand Area  --> */}


        {/* <!--=====================================-->
        <!--=       WHAT YOU NEED TO JOIN US Area Start      	=-->
        <!--=====================================-->
        <!-- WHAT YOU NEED TO JOIN US Area  --> */}
        <div className="why-choose-area-1 section-gap-equal">
            <div className="container">
                <div className="row g-5 row--45">
                    <div className="col-lg-6">
                        <div className="why-choose-content">
                            <div className="section-title section-left" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                                <span className="pre-title pre-textsecondary">Join US</span>
                                <h2 className="title">What you need <br/> to Join Us ?</h2>
                                <span className="shape-line"><i className="icon-19"></i></span>
                                <p>Magna aliquaenim minim veniam quis nostrud exercitation ullamco laborisLorem ipsum dolor sit amet consectetur.</p>
                            </div>
                            <div className="features-list" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                                <div className="features-box color-secondary-style">
                                    <div className="icon">
                                        <i className="icon-81"></i>
                                    </div>
                                    <div className="content">
                                        <h5 className="title">Notebook</h5>
                                        <p>You will need a notebook, to take notes for the course and record your questions.</p>
                                    </div>
                                </div>
                                <div className="features-box color-primary-style">
                                    <div className="icon">
                                        <i className="icon-82"></i>
                                    </div>
                                    <div className="content">
                                        <h5 className="title">Laptop</h5>
                                        <p>Follow the link below to view Laptop requirements and recommendations for our program.</p>
                                    </div>
                                </div>
                                <div className="features-box color-extra05-style">
                                    <div className="icon">
                                        <i className="icon-83"></i>
                                    </div>
                                    <div className="content">
                                        <h5 className="title">Passion</h5>
                                        <p>Essential in everything you do. It’s the fuel that gives you the strength to keep going even when it’s hard.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="why-choose-gallery">
                            <div className="gallery-thumbnail">
                                <div className="thumbnail thumbnail-1">
                                    <img src="/assets/images/others/why-choose-01-1.jpg" alt="Why Choose"/>
                                </div>
                                <div className="thumbnail thumbnail-2" data-parallax='{"x": 0, "y": -60}'>
                                    <img src="/assets/images/others/why-choose-02-1.jpg" alt="Why Choose"/>
                                </div>
                            </div>
                            <ul className="shape-group">
                                <li className="shape-1" data-sal-delay="500" data-sal="fade" data-sal-duration="200">
                                    <img src="/assets/images/about/shape-14.png" alt="Shape Images"/>
                                </li>
                                <li className="shape-2" data-sal-delay="500" data-sal="fade" data-sal-duration="200">
                                    <img src="/assets/images/about/shape-10.png" alt="Shape Images"/>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* <!-- End WHAT YOU NEED TO JOIN US Area  --> */}

        {/* <!--=====================================-->
        <!--=       	Have a Degree Area Start      	=-->
        <!--=====================================-->
        <!-- Have a Degree Area  --> */}
        <div className="benefits-area-1">
            <div className="benefits-wrapper">
                <div className="benefits-wrap-one">

                </div>
                <div className="benefits-wrap-two">
                    <div className="process-gallery edublink-animated-shape">
                        <div className="gallery-thumbnail">
                            <div className="thumbnail thumbnail-1">
                                <img src="/assets/images/others/benefit-img-01-1.jpg" alt="Why Choose"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container edublink-animated-shape benefits-content-box">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="process-content">
                                <div className="section-title section-left" data-sal-delay="100" data-sal="slide-up" data-sal-duration="1000">
                                    <span className="pre-title color-secondary"> Expertise</span>
                                    <h2 className="title">Do I need to have <span className="color-secondary"><br/>a Degree or Knowledge  <br/> </span> in Computer Sciences ?</h2>
                                    <span className="shape-line"><i className="icon-19"></i></span>
                                    <p>
                                        Our training program takes people from various backgrounds and different study levels and molds them into IT professionals ready for work !
                                        You don’t need any previous computer knowledge to take this course. In 6 months, we will take you from zero to hero in the skills we teach.
                                    </p>
                                </div>
                                <div className="features-wrapper" data-sal-delay="150" data-sal="slide-up" data-sal-duration="1000">
                                    <ul className="features-list">
                                        <li>No former experience in computer science is needed !!</li>
                                        <li>Personal Trainer</li>
                                        <li>Flexible Classes</li>
                                    </ul>
                                </div>
                                {/* <div className="features-btn" data-sal-delay="200" data-sal="slide-up" data-sal-duration="1000">
                                    <Link to="/course_one" className="edu-btn">Learn More <i className="icon-4"></i></Link>
                                </div> */}
                                <ul className="shape-group">
                                    <li className="shape-1" data-sal-delay="500" data-sal="fade" data-sal-duration="200">
                                        <span></span>
                                    </li>
                                    <li className="shape-2 scene" data-sal-delay="500" data-sal="fade" data-sal-duration="200">
                                        <img data-depth="2" src="/assets/images/counterup/shape-02.png" alt="Shape Images"/>
                                    </li>
                                    <li className="shape-3 scene" data-sal-delay="500" data-sal="fade" data-sal-duration="200">
                                        <img data-depth="-2" src="/assets/images/cta/shape-14.png" alt="Shape Images"/>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6"></div>
                    </div>
                </div>
            </div>
        </div>
        
        {/* <!-- End Why Choose Area  --> */}


        {/* <!--=====================================-->
        <!--=       Course Area Start      		=-->
        <!--=====================================-->
        <!-- Start Course Area  --> */}
        <div className="edu-course-area course-area-1 edu-section-gap bg-lighten01">
            <div className="container">
                <div className="section-title section-center" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                    <span className="pre-title">ONGOING SESSION & UPCOMING SESSION</span>
                    <h2 className="title">Pick A Course or Sesion To Get Started</h2>
                    <span className="shape-line"><i className="icon-19"></i></span>
                </div>
                <div className="row g-5 justify-content-center">
                    {/* <!-- Start Single Course  --> */}
                    <div className="col-md-6 col-xl-3" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                        <div className="edu-session course-style-1 hover-button-bg-white">
                            <div className="inner">
                                <div className="thumbnail">
                                    <Link to="/course_details_one">
                                        <img src="/assets/images/course/course-07.jpg" alt="Course Meta"/>
                                    </Link>
                                    <div className="time-top">
                                        <span className="duration"><i className="icon-62"></i>Session</span>
                                    </div>
                                </div>
                                <div className="content">
                                    <span className="course-level ">Closedd</span>
                                    <h6 className="title">
                                        <Link to="#">Linux, AWS & DevOps training November 2023</Link>
                                    </h6>
                                    <div className="course-rating">
                                        <div className="rating">
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                        </div>
                                        <span className="rating-count">(4.9 /8 Rating)</span>
                                    </div>
                                    <div className="course-price">NOVEMBER SESSION</div>
                                    <Link to="/course_details_one" className="utrains-btn btn-secondary btn-small invisble-on-hover">Enroll Today <i className="icon-4"></i></Link>
                                    <ul className="course-meta">
                                        <li><i className="icon-24 text-white"></i>13 Lessons</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="course-hover-content-wrapper">
                                <button className="wishlist-btn"><i className="icon-22"></i></button>
                            </div>
                            <div className="course-hover-content">
                                <div className="content">
                                    <button className="wishlist-btn"><i className="icon-22"></i></button>
                                    <span className="course-level">Closed</span>
                                    <h6 className="title">
                                        <Link to="/course_details_one">Linux, AWS & DevOps training November 2023</Link>
                                    </h6>
                                    <div className="course-rating">
                                        <div className="rating">
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                        </div>
                                        <span className="rating-count">(4.9 /8 Rating)</span>
                                    </div>
                                    <div className="course-price">NOVEMBER SESSION</div>
                                    <p>The November session started on December 11, 2023. Registration is close!.</p>
                                    <ul className="course-meta">
                                        <li><i className="icon-24"></i>13 Lessons</li>
                                    </ul>
                                    <Link to="/course_details_one" className="edu-btn btn-secondary btn-small">Enroll Today <i className="icon-4"></i></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- End Single Course  --> */}

                    {/* <!-- Start Single Course  --> */}
                    <div className="col-md-6 col-xl-3" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                        <div className="edu-course course-style-1 hover-button-bg-white">
                            <div className="inner">
                                <div className="thumbnail">
                                    <Link to="/course_details_one">
                                        <img src="/assets/images/course/course-04.jpg" alt="Course Meta"/>
                                    </Link>
                                    <div className="time-top">
                                        <span className="duration"><i className="icon-62"></i>Session</span>
                                    </div>
                                </div>
                                <div className="content">
                                    <span className="course-level">Opened</span>
                                    <h6 className="title">
                                        <Link to="#">Linux, AWS & DevOps training March 2024</Link>
                                    </h6>
                                    <div className="course-rating">
                                        <div className="rating">
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                        </div>
                                        <span className="rating-count">(5.0 /7 Rating)</span>
                                    </div>
                                    <div className="course-price">MARCH SESSION</div>
                                    <Link to="/course_details_one" className="edu-btn btn-secondary btn-small invisble-on-hover">Enroll Now <i className="icon-4"></i></Link>
                                    <ul className="course-meta">
                                        <li><i className="icon-24"></i>8 Lessons</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="course-hover-content-wrapper">
                                <button className="wishlist-btn"><i className="icon-22"></i></button>
                            </div>
                            <div className="course-hover-content">
                                <div className="content">
                                    <button className="wishlist-btn"><i className="icon-22"></i></button>
                                    <span className="course-level">Beginner</span>
                                    <h6 className="title">
                                        <Link to="/course_details_one">Linux, AWS & DevOps training March 2024</Link>
                                    </h6>
                                    <div className="course-rating">
                                        <div className="rating">
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                        </div>
                                        <span className="rating-count">(5.0 /7 Rating)</span>
                                    </div>
                                    <div className="course-price">MARCH SESSION</div>
                                    <p>The March 2024 session will start on April 20, 2024. Registration is already open.</p>
                                    <Link to="/course_details_one" className="edu-btn btn-secondary btn-small invisble-on-hover">Enroll Now <i className="icon-4"></i></Link>
                                    <ul className="course-meta">
                                        <li><i className="icon-24"></i>8 Lessons</li>
                                    </ul>
                                    <Link to="/course_details_one" className="edu-btn btn-secondary btn-small">Enroll Today <i className="icon-4"></i></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- End Single Course  --> */}

                    {/* <!-- Start Single Course  --> */}
                    <div className="col-md-6 col-xl-3" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                        <div className="edu-course course-style-1 hover-button-bg-white">
                            <div className="inner">
                                <div className="thumbnail">
                                    <Link to="/course_details_one">
                                        <img src="/assets/images/course/course-05.jpg" alt="Course Meta"/>
                                    </Link>
                                    <div className="time-top">
                                        <span className="duration"><i className="icon-63"></i>Course</span>
                                    </div>
                                </div>
                                <div className="content">
                                    <span className="course-level">Advanced</span>
                                    <h6 className="title">
                                        <Link to="#">HashiCorp Certified: Terraform Associate Preparation course </Link>
                                    </h6>
                                    <div className="course-rating">
                                        <div className="rating">
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                        </div>
                                        <span className="rating-count">(4.8 /9 Rating)</span>
                                    </div>
                                    <div className="course-price">$35.00</div>
                                    <Link to="/course_details_one" className="edu-btn btn-secondary btn-small invisble-on-hover">Enroll Now <i className="icon-4"></i></Link>
                                    <ul className="course-meta">
                                        <li><i className="icon-24"></i>32 Lessons</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="course-hover-content-wrapper">
                                <button className="wishlist-btn"><i className="icon-22"></i></button>
                            </div>
                            <div className="course-hover-content">
                                <div className="content">
                                    <button className="wishlist-btn"><i className="icon-22"></i></button>
                                    <span className="course-level">Advanced</span>
                                    <h6 className="title">
                                        <Link to="/course_details_one">HashiCorp Certified: Terraform Associate Preparation course </Link>
                                    </h6>
                                    <div className="course-rating">
                                        <div className="rating">
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                        </div>
                                        <span className="rating-count">(4.8 /9 Rating)</span>
                                    </div>
                                    <div className="course-price">$29.00</div>
                                    <p>Are you ready to take your DevOps career to new heights? Unlock the potential of infrastructure automation with Terraform and position yourself for success in the competitive tech landscape. Our comprehensive Terraform Certification Preparation Course is designed for individuals aspiring to achieve the Terraform Associate certification. </p>
                                    <Link to="/course_details_one" className="edu-btn btn-secondary btn-small invisble-on-hover">Enroll Now <i className="icon-4"></i></Link>
                                    <ul className="course-meta">
                                        <li><i className="icon-24"></i>35 Lessons</li>
                                    </ul>
                                    <Link to="/course_details_one" className="edu-btn btn-secondary btn-small">Enrolled <i className="icon-4"></i></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- End Single Course  --> */}

                    {/* <!-- Start Single Course  --> */}
                    <div className="col-md-6 col-xl-3" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                        <div className="edu-course course-style-1 hover-button-bg-white">
                            <div className="inner">
                                <div className="thumbnail">
                                    <Link to="/course_details_one">
                                        <img src="/assets/images/course/course-06.jpg" alt="Course Meta"/>
                                    </Link>
                                    <div className="time-top">
                                        <span className="duration"><i className="icon-63"></i>Course</span>
                                    </div>
                                </div>
                                <div className="content">
                                    <span className="course-level">Intermediate</span>
                                    <h6 className="title">
                                        <Link to="#">CKA Exam Prep Course</Link>
                                    </h6>
                                    <div className="course-rating">
                                        <div className="rating">
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                        </div>
                                        <span className="rating-count">(4.7 /5 Rating)</span>
                                    </div>
                                    <div className="course-price">$49.00</div>
                                    <Link to="/course_details_one" className="edu-btn btn-secondary btn-small invisble-on-hover">Enroll Now <i className="icon-4"></i></Link>
                                    <ul className="course-meta">
                                        <li><i className="icon-24"></i>15 Lessons</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="course-hover-content-wrapper">
                                <button className="wishlist-btn"><i className="icon-22"></i></button>
                            </div>
                            <div className="course-hover-content">
                                <div className="content">
                                    <button className="wishlist-btn"><i className="icon-22"></i></button>
                                    <span className="course-level">Intermediate</span>
                                    <h6 className="title">
                                        <Link to="/course_details_one">CKA Exam Prep Course</Link>
                                    </h6>
                                    <div className="course-rating">
                                        <div className="rating">
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                        </div>
                                        <span className="rating-count">(4.7 /5 Rating)</span>
                                    </div>
                                    <div className="course-price">$49.00</div>
                                    <p>Without a doubt, Kubernetes is the most prominent container orchestration tool. And you’ve probably noticed that many positions available to IT professionals require Kubernetes experience. One way to gain or prove your Kubernetes knowledge is by becoming a Certified Kubernetes Administrator (CKA).</p>
                                    <ul className="course-meta">
                                        <li><i className="icon-24"></i>15 Lessons</li>
                                    </ul>
                                    <Link to="/course_details_one" className="edu-btn btn-secondary btn-small">Enrolled <i className="icon-4"></i></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- End Single Course  --> */}

                </div>
                <div className="course-view-all" data-sal-delay="150" data-sal="slide-up" data-sal-duration="1200">
                    <Link to="/course_one" className="edu-btn">Browse more courses <i className="icon-4"></i></Link>
                </div>
            </div>
        </div>
        {/* <!-- End Course Area -->

        <!--=====================================-->
        <!--=       CounterUp Area Start      	=-->
        <!--=====================================--> */}
        <div className="counterup-area-2">
            <div className="container">
                <div className="row g-5 justify-content-center">
                    <div className="col-lg-8">
                        <div className="counterup-box-wrap">
                            <div className="counterup-box counterup-box-1">
                                <div className="edu-counterup counterup-style-2">
                                    <h2 className="counter-item count-number primary-color">
                                        <span className="odometer" data-odometer-final="45.2">.</span><span>K</span>
                                    </h2>
                                    <h6 className="title">Student Enrolled</h6>
                                </div>
                                <div className="edu-counterup counterup-style-2">
                                    <h2 className="counter-item count-number secondary-color">
                                        <span className="odometer" data-odometer-final="32.4">.</span><span>K</span>
                                    </h2>
                                    <h6 className="title">className Completed</h6>
                                </div>
                            </div>
                            <div className="counterup-box counterup-box-2">
                                <div className="edu-counterup counterup-style-2">
                                    <h2 className="counter-item count-number extra05-color">
                                        <span className="odometer" data-odometer-final="354">.</span><span>+</span>
                                    </h2>
                                    <h6 className="title">Top Instructors</h6>
                                </div>
                                <div className="edu-counterup counterup-style-2">
                                    <h2 className="counter-item count-number extra02-color">
                                        <span className="odometer" data-odometer-final="99.9">.</span><span>%</span>
                                    </h2>
                                    <h6 className="title">Satisfaction Rate</h6>
                                </div>
                            </div>
                            <ul className="shape-group">
                                <li className="shape-1 scene">
                                    <img data-depth="-2" src="/assets/images/about/shape-13.png" alt="Shape"/>
                                </li>
                                <li className="shape-2">
                                    <img className="rotateit" src="/assets/images/counterup/shape-02.png" alt="Shape"/>
                                </li>
                                <li className="shape-3 scene">
                                    <img data-depth="1.6" src="/assets/images/counterup/shape-04.png" alt="Shape"/>
                                </li>
                                <li className="shape-4 scene">
                                    <img data-depth="-1.6" src="/assets/images/counterup/shape-05.png" alt="Shape"/>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        {/* <!--=====================================-->
        <!--=       Testimonial Area Start      =-->
        <!--=====================================-->
        <!-- Start Testimonial Area  testimonial-area-18 testimonial-area-1  --> */}
        <div className="testimonial-area-5 utrains-testimonials-gap-v">
            <div className="container">
                <div className="row g-lg-5">
                    <div className="col-lg-5">
                        <div className="testimonial-heading-area">
                            <div className="section-title section-left" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                                <span className="pre-title">Testimonials</span>
                                <h2 className="title">A lot of Happy Students</h2>
                                <span className="shape-line"><i className="icon-19"></i></span>
                                <p>They are the pride of a growing value that we cease to offer. Thanks to you, we're growing together.</p>
                                <Link to="/testimonials" className="edu-btn btn-large">View All<i className="icon-4"></i></Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-7">
                        <div className="swiper-testimonial-slider-wrapper swiper testimonial-coverflow">
                            <div className="swiper-wrapper">
                                <div className="swiper-slide">
                                    <div className="testimonial-grid">
                                        <div className="thumbnail">
                                            <img src="/assets/images/testimonial/testimonial-01.png" alt="Testimonial"/>
                                            <span className="qoute-icon"><i className="icon-26"></i></span>

                                        </div>
                                        <div className="content">
                                            <p>Lorem ipsum dolor amet consec tur elit adicing sed do usmod zx tempor enim minim veniam quis nostrud exer citation.</p>
                                            <div className="rating-icon">
                                                <i className="icon-23"></i>
                                                <i className="icon-23"></i>
                                                <i className="icon-23"></i>
                                                <i className="icon-23"></i>
                                                <i className="icon-23"></i>
                                            </div>
                                            <h5 className="title">Ray Sanchez</h5>
                                            <span className="subtitle">Student</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="testimonial-grid">
                                        <div className="thumbnail">
                                            <img src="/assets/images/testimonial/testimonial-02.png" alt="Testimonial"/>
                                            <span className="qoute-icon"><i className="icon-26"></i></span>

                                        </div>
                                        <div className="content">
                                            <p>Lorem ipsum dolor amet consec tur elit adicing sed do usmod zx tempor enim minim veniam quis nostrud exer citation.</p>
                                            <div className="rating-icon">
                                                <i className="icon-23"></i>
                                                <i className="icon-23"></i>
                                                <i className="icon-23"></i>
                                                <i className="icon-23"></i>
                                                <i className="icon-23"></i>
                                            </div>
                                            <h5 className="title">Thomas Lopez</h5>
                                            <span className="subtitle">Designer</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="testimonial-grid">
                                        <div className="thumbnail">
                                            <img src="/assets/images/testimonial/testimonial-03.png" alt="Testimonial"/>
                                            <span className="qoute-icon"><i className="icon-26"></i></span>

                                        </div>
                                        <div className="content">
                                            <p>Lorem ipsum dolor amet consec tur elit adicing sed do usmod zx tempor enim minim veniam quis nostrud exer citation.</p>
                                            <div className="rating-icon">
                                                <i className="icon-23"></i>
                                                <i className="icon-23"></i>
                                                <i className="icon-23"></i>
                                                <i className="icon-23"></i>
                                                <i className="icon-23"></i>
                                            </div>
                                            <h5 className="title">Amber Page</h5>
                                            <span className="subtitle">Developer</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="testimonial-grid">
                                        <div className="thumbnail">
                                            <img src="/assets/images/testimonial/testimonial-04.png" alt="Testimonial"/>
                                            <span className="qoute-icon"><i className="icon-26"></i></span>

                                        </div>
                                        <div className="content">
                                            <p>Lorem ipsum dolor amet consec tur elit adicing sed do usmod zx tempor enim minim veniam quis nostrud exer citation.</p>
                                            <div className="rating-icon">
                                                <i className="icon-23"></i>
                                                <i className="icon-23"></i>
                                                <i className="icon-23"></i>
                                                <i className="icon-23"></i>
                                                <i className="icon-23"></i>
                                            </div>
                                            <h5 className="title">Robert Tapp</h5>
                                            <span className="subtitle">Content Creator</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="swiper-pagination"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        {/* <!--=====================================-->
        <!--=      CTA Banner Area Start   		=-->
        <!--=====================================-->
        <!-- Start Ad Banner Area  --> */}
        <div className="edu-cta-banner-area home-one-cta-wrapper bg-image">
            <div className="container">
                <div className="edu-cta-banner">
                    <div className="row justify-content-center">
                        <div className="col-lg-7">
                            <div className="section-title section-center" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                                <h2 className="title">Access our <span className="color-secondary">Free Master Class </span> launched by Utrains </h2>
                                <Link to="/sessions/next" className="edu-btn">Join now <i className="icon-4"></i></Link>
                            </div>
                        </div>
                    </div>
                    <ul className="shape-group">
                        <li className="shape-01 scene">
                            <img data-depth="2.5" src="/assets/images/cta/shape-10.png" alt="shape"/>
                        </li>
                        <li className="shape-02 scene">
                            <img data-depth="-2.5" src="/assets/images/cta/shape-09.png" alt="shape"/>
                        </li>
                        <li className="shape-03 scene">
                            <img data-depth="-2" src="/assets/images/cta/shape-08.png" alt="shape"/>
                        </li>
                        <li className="shape-04 scene">
                            <img data-depth="2" src="/assets/images/about/shape-13.png" alt="shape"/>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        {/* <!-- End Ad Banner Area  --> */}

        {/* <!--=====================================-->
        <!--=       Job Title Area Start      	=-->
        <!--=====================================-->
        <!-- Start Job Title Area  --> */}
        <div className="edu-categorie-area categorie-area-3 edu-section-gap bg-image" id="categories">
            <div className="container">
                <div className="section-title section-center" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                    <span className="pre-title pre-textsecondary">Job Titles</span>
                    <h2 className="title">With <span className="color-primary">our training </span> you will gain valuable skillsets that allow you to hold one of the following job titles.</h2>
                    <span className="shape-line"><i className="icon-19"></i></span>
                    
                </div>
                <div className="row row-cols-xl-5 row-cols-lg-4 row-cols-md-3 row-cols-sm-2 row-cols-1 g-4">
                    {/* ok */}
                    <div className="col" data-sal-delay="100" data-sal="slide-up" data-sal-duration="800">
                        <div className="categorie-grid categorie-style-3 color-primary-style">
                            <div className="icon">
                                <i className="icon-9"></i>
                            </div>
                            <div className="content">
                                <Link to="/course_one">
                                    <h5 className="title">Automation Engineer</h5>
                                </Link>
                            </div>
                        </div>
                    </div>

                    {/* ok */}
                    <div className="col" data-sal-delay="250" data-sal="slide-up" data-sal-duration="800">
                        <div className="categorie-grid categorie-style-3 color-tertiary-style">
                            <div className="icon">
                                <i className="icon-12"></i>
                            </div>
                            <div className="content">
                                <Link to="/course_one">
                                    <h5 className="title">Linux Administrator</h5>
                                </Link>
                            </div>
                        </div>
                    </div>

                    {/* ok */}
                    <div className="col" data-sal-delay="300" data-sal="slide-up" data-sal-duration="800">
                        <div className="categorie-grid categorie-style-3 color-extra02-style">
                            <div className="icon">
                                <i className="icon-13"></i>
                            </div>
                            <div className="content">
                                <Link to="/course_one">
                                    <h5 className="title">Cloud Support Engineer</h5>
                                </Link>
                            </div>
                        </div>
                    </div>

                    {/* ok */}
                    <div className="col" data-sal-delay="100" data-sal="slide-up" data-sal-duration="800">
                        <div className="categorie-grid categorie-style-3 color-extra07-style">
                            <div className="icon design-pencil-icon">
                                <i className="icon-42"></i>
                            </div>
                            <div className="content">
                                <Link to="/course_one">
                                    <h5 className="title">Site Reliability Engineer</h5>
                                </Link>
                            </div>
                        </div>
                    </div>

                    {/* ok */}
                    <div className="col" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                        <div className="categorie-grid categorie-style-3 color-secondary-style">
                            <div className="icon">
                                <i className="icon-10"></i>
                            </div>
                            <div className="content">
                                <Link to="/course_one">
                                    <h5 className="title">DevOps Engineer</h5>
                                </Link>
                            </div>
                        </div>
                    </div>

                    {/* ok */}
                    <div className="col" data-sal-delay="200" data-sal="slide-up" data-sal-duration="800">
                        <div className="categorie-grid categorie-style-3 color-extra04-style">
                            <div className="icon">
                                <i className="icon-11"></i>
                            </div>
                            <div className="content">
                                <Link to="/course_one">
                                    <h5 className="title">Software Engineer</h5>
                                </Link>
                            </div>
                        </div>
                    </div>

                    {/* ok */}
                    <div className="col" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                        <div className="categorie-grid categorie-style-3 color-extra06-style">
                            <div className="icon">
                                <i className="icon-14"></i>
                            </div>
                            <div className="content">
                                <Link to="/course_one">
                                    <h5 className="title">System Analyst</h5>
                                </Link>
                            </div>
                        </div>
                    </div>

                    {/* ok */}
                    <div className="col" data-sal-delay="200" data-sal="slide-up" data-sal-duration="800">
                        <div className="categorie-grid categorie-style-3 color-extra03-style">
                            <div className="icon laptop-icon">
                                <i className="icon-16"></i>
                            </div>
                            <div className="content">
                                <Link to="/course_one">
                                    <h5 className="title">CI/CD Engineer</h5>
                                </Link>
                            </div>
                        </div>
                    </div>

                    <div className="col" data-sal-delay="250" data-sal="slide-up" data-sal-duration="800">
                        <div className="categorie-grid categorie-style-3 color-extra01-style">
                            <div className="icon">
                                <i className="icon-17"></i>
                            </div>
                            <div className="content">
                                <Link to="/course_one">
                                    <h5 className="title">Photography</h5>
                                </Link>
                            </div>
                        </div>
                    </div>

                    <div className="col" data-sal-delay="300" data-sal="slide-up" data-sal-duration="800">
                        <div className="categorie-grid categorie-style-3 color-extra05-style">
                            <div className="icon">
                                <i className="icon-43"></i>
                            </div>
                            <div className="content">
                                <Link to="/course_one">
                                    <h5 className="title">Music Class</h5>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* <!-- End Job Title Area  --> */}


        {/* <!--=====================================-->
        <!--=      		Companies Area Start   		=-->
        <!--=====================================-->
        <!-- Start Companies Area  --> */}
        <div className="edu-brand-area brand-area-1 utrains-company-gap-equal utrains-company-area">
            <div className="container">
                <div className="row">

                    <div className="col-lg-5">
                        <div className="brand-section-heading">
                            <div className="section-title section-left" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                                <span className="pre-title">Our Students</span>
                                <h2 className="title">Companies where our students have worked</h2>
                                <span className="shape-line"><i className="icon-19"></i></span>
                                <p>
                                    On the left are the companies where our students work and are the pride of their service companies.
                                </p>
                            </div> 
                            <Link class="edu-btn" to="/companies-students-worked">View All <i class="icon-4"></i></Link>
                        </div>
                    </div>
                    <div className="col-lg-7">
                        <div className="home-one-testimonial-activator swiper ">
                            <div className="swiper-wrapper">
                                <div className="swiper-slide">
                                    <div className="utrains-brand-grid-wrap">
                                        <div className="brand-grid">
                                            <img src="/assets/images/brand/brand-01.png" alt="Brand Logo"/>
                                        </div>
                                        <div className="brand-grid">
                                            <img src="/assets/images/brand/brand-02.png" alt="Brand Logo"/>
                                        </div>
                                        <div className="brand-grid">
                                            <img src="/assets/images/brand/brand-03.png" alt="Brand Logo"/>
                                        </div>
                                        <div className="brand-grid">
                                            <img src="/assets/images/brand/brand-04.png" alt="Brand Logo"/>
                                        </div>
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="utrains-brand-grid-wrap">
                                        <div className="brand-grid">
                                            <img src="/assets/images/brand/brand-08-1.png" alt="Brand Logo"/>
                                        </div>
                                        <div className="brand-grid">
                                            <img src="/assets/images/brand/brand-08-2.png" alt="Brand Logo"/>
                                        </div>
                                        <div className="brand-grid">
                                            <img src="/assets/images/brand/brand-08-3.png" alt="Brand Logo"/>
                                        </div>
                                        <div className="brand-grid">
                                            <img src="/assets/images/brand/brand-08-4.png" alt="Brand Logo"/>
                                        </div>

                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="utrains-brand-grid-wrap">
                                        <div className="brand-grid">
                                            <img src="/assets/images/brand/brand-05.png" alt="Brand Logo"/>
                                        </div>
                                        <div className="brand-grid">
                                            <img src="/assets/images/brand/brand-06.png" alt="Brand Logo"/>
                                        </div>
                                        <div className="brand-grid">
                                            <img src="/assets/images/brand/brand-07.png" alt="Brand Logo"/>
                                        </div>
                                        <div className="brand-grid">
                                            <img src="/assets/images/brand/brand-08.png" alt="Brand Logo"/>
                                        </div>
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="utrains-brand-grid-wrap">
                                        <div className="brand-grid">
                                            <img src="/assets/images/brand/brand-08-5.png" alt="Brand Logo"/>
                                        </div>
                                        <div className="brand-grid">
                                        </div>
                                        <div className="brand-grid">
                                        </div>
                                        <div className="brand-grid">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>

                    {/* <div className="col-lg-12">
                        <div className="features-wrapper" data-sal-delay="150" data-sal="slide-up" data-sal-duration="1000">
                            <ul className="features-list">
                                <li>Automation Engineer</li>
                                <li>Cloud Support Engineer</li>
                                <li>DevOps Engineer</li>
                                <li>Site Reliability Engineer</li>
                                
                            </ul>
                            <ul className="features-list">
                                <li>Linux Administrator</li>
                                <li>Software Engineer</li>
                                <li>System Analyst</li>
                                <li>CI/CD Engineer</li>
                            </ul>
                        </div>
                    </div> */}
                    
                </div>
            </div>
        </div>
        {/* <!-- End Companies Area  --> */}

        

        {/* <!--=====================================-->
        <!--=      Call To Action Area Start   	=-->
        <!--=====================================-->
        <!-- Start CTA Area  --> */}
        <div className="home-one-cta-two cta-area-1 utrains-cta-gap-top ">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-xl-8">
                        <div className="home-one-cta edu-cta-box bg-image">
                            <div className="inner">
                                <div className="content text-md-end">
                                    <span className="subtitle">Get In Touch:</span>
                                    <h3 className="title"><Link to="mailto:contact@utrains.org">contact@utrains.org</Link></h3>
                                </div>
                                <div className="sparator">
                                    <span>or</span>
                                </div>
                                <div className="content">
                                    <span className="subtitle">Call Us Via:</span>
                                    <h3 className="title"><Link to="tel:+016893440">+01 689 3440</Link></h3>
                                </div>
                            </div>
                            <ul className="shape-group">
                                <li className="shape-01 scene">
                                    <img data-depth="2" src="/assets/images/cta/shape-06.png" alt="shape"/>
                                </li>
                                <li className="shape-02 scene">
                                    <img data-depth="-2" src="/assets/images/cta/shape-12.png" alt="shape"/>
                                </li>
                                <li className="shape-03 scene">
                                    <img data-depth="-3" src="/assets/images/cta/shape-04.png" alt="shape"/>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* <!-- End CTA Area  --> */}

        {/* <!--=====================================-->
        <!--=      		Blog Area Start   		=-->
        <!--=====================================-->
        <!-- Start Blog Area  --> */}
        <div className="edu-blog-area blog-area-1 edu-section-gap">
            <div className="container">
                <div className="section-title section-center" data-sal-delay="100" data-sal="slide-up" data-sal-duration="800">
                    <span className="pre-title">Latest Articles</span>
                    <h2 className="title">Get News with Utrains.org</h2>
                    <span className="shape-line"><i className="icon-19"></i></span>
                </div>
                <div className="row g-5">
                    {/* <!-- Start Blog Grid  --> */}
                    <div className="col-lg-4 col-md-6 col-12" data-sal-delay="100" data-sal="slide-up" data-sal-duration="800">
                        <div className="edu-blog blog-style-1">
                            <div className="inner">
                                <div className="thumbnail">
                                    <Link to="/blog_details">
                                        <img src="/assets/images/blog/blog-01.jpg" alt="Blog Images"/>
                                    </Link>
                                </div>
                                <div className="content position-top">
                                    <div className="read-more-btn">
                                        <Link to className="btn-icon-round" href="/blog_details"><i className="icon-4"></i></Link>
                                    </div>
                                    <div className="category-wrap">
                                        <Link to="#" className="blog-category">ONLINE</Link>
                                    </div>
                                    <h5 className="title"><Link to="/blog_details">Become a Terraform Architect</Link></h5>
                                    <ul className="blog-meta">
                                        <li><i className="icon-27"></i>Oct 10, 2024</li>
                                        <li><i className="icon-28"></i>Com 09</li>
                                    </ul>
                                    <p>Lorem ipsum dolor sit amet cons tetur adipisicing sed.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- End Blog Grid  --> */}
                    {/* <!-- Start Blog Grid  --> */}
                    <div className="col-lg-4 col-md-6 col-12" data-sal-delay="200" data-sal="slide-up" data-sal-duration="800">
                        <div className="edu-blog blog-style-1">
                            <div className="inner">
                                <div className="thumbnail">
                                    <Link to="/blog_details">
                                        <img src="/assets/images/blog/blog-02.jpg" alt="Blog Images"/>
                                    </Link>
                                </div>
                                <div className="content position-top">
                                    <div className="read-more-btn">
                                        <Link to className="btn-icon-round" href="/blog_details"><i className="icon-4"></i></Link>
                                    </div>
                                    <div className="category-wrap">
                                        <Link to="#" className="blog-category">LECTURE</Link>
                                    </div>
                                    <h5 className="title"><Link to="/blog_details">How to Deploy Kubernetes on Bare-Metal Server</Link></h5>
                                    <ul className="blog-meta">
                                        <li><i className="icon-27"></i>Oct 10, 2024</li>
                                        <li><i className="icon-28"></i>Com 09</li>
                                    </ul>
                                    <p>Lorem ipsum dolor sit amet cons tetur adipisicing sed do eiusmod ux tempor incid idunt labore dol oremagna aliqua.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- End Blog Grid  --> */}
                    {/* <!-- Start Blog Grid  --> */}
                    <div className="col-lg-4 col-md-6 col-12" data-sal-delay="300" data-sal="slide-up" data-sal-duration="800">
                        <div className="edu-blog blog-style-1">
                            <div className="inner">
                                <div className="thumbnail">
                                    <Link to="/blog_details">
                                        <img src="/assets/images/blog/blog-03.jpg" alt="Blog Images"/>
                                    </Link>
                                </div>
                                <div className="content position-top">
                                    <div className="read-more-btn">
                                        <Link to className="btn-icon-round" href="/blog_details"><i className="icon-4"></i></Link>
                                    </div>
                                    <div className="category-wrap">
                                        <Link to="#" className="blog-category">BUSINESS</Link>
                                    </div>
                                    <h5 className="title"><Link to="/blog_details">Four Ways to use Ansible</Link></h5>
                                    <ul className="blog-meta">
                                        <li><i className="icon-27"></i>Oct 10, 2024</li>
                                        <li><i className="icon-28"></i>Com 09</li>
                                    </ul>
                                    <p>Lorem ipsum dolor sit amet cons tetur adipisicing sed do eiusmod ux tempor incid idunt.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- End Blog Grid  --> */}
                </div>
            </div>
            <ul className="shape-group">
                <li className="shape-1 scene">
                    <img data-depth="-1.4" src="/assets/images/about/shape-02.png" alt="Shape"/>
                </li>
                <li className="shape-2 scene">
                    {/* <span data-depth="2.5"></span> */}
                    <img data-depth="1.6" src="/assets/images/counterup/shape-06.png" alt="Shape"/>
                </li>
                <li className="shape-3 scene">
                    <img data-depth="-2.3" src="/assets/images/counterup/shape-05.png" alt="Shape"/>
                </li>
            </ul>
        </div>
        {/* <!-- End Blog Area  --> */}
    </div>
    
  )
}

export default Side
