import React from 'react';

const TabLink = ({ title, id, isActive, onClick }) => {
  return (
    <li className={`nav-item ${isActive ? 'active' : ''}`} role="presentation">
      <button
        className={`nav-link ${isActive ? 'active' : ''}`}
        onClick={() => onClick(id)}
        type="button"
        role="tab"
        aria-selected={isActive}
      >
        {title}
      </button>
    </li>
  );
};

export default TabLink;