import React from 'react'
import { Link } from 'react-router-dom'
import ContactInfo from './ContactInfo'
import ContactForm from './ContactForm'

function ContactUsContent() {
    return (
        <div>

            <div className="edu-breadcrumb-area">
                <div className="container">
                    <div className="breadcrumb-inner">
                        <div className="page-title">
                            <h1 className="title">Contact Us</h1>
                        </div>
                        <ul className="edu-breadcrumb">
                            <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                            <li className="separator"><i className="icon-angle-right"></i></li>
                            <li className="breadcrumb-item active" aria-current="page">Contact Us</li>
                        </ul>
                    </div>
                </div>
                <ul className="shape-group">
                    <li className="shape-1">
                        <span></span>
                    </li>
                    <li className="shape-2 scene"><img data-depth="2" src="assets/images/about/shape-13.png" alt="shape" /></li>
                    <li className="shape-3 scene"><img data-depth="-2" src="assets/images/about/shape-15.png" alt="shape" /></li>
                    <li className="shape-4">
                        <span></span>
                    </li>
                    <li className="shape-5 scene"><img data-depth="2" src="assets/images/about/shape-07.png" alt="shape" /></li>
                </ul>
            </div>

            {/* <!--=====================================-->
        <!--=       Contact Me Area Start       =-->
        <!--=====================================--> */}
            <section className="contact-us-area">
                <div className="container">
                    <div className="row g-5">
                        <div className="col-xl-4 col-lg-6">
                            <ContactInfo />
                        </div>
                        <div className="offset-xl-2 col-lg-6">
                            <ContactForm />
                        </div>
                    </div>
                </div>
            </section>
            {/* <!--=====================================-->
        <!--=      Google Map Area Start        =-->
        <!--=====================================--> */}
            <div className="google-map-area">
                <div className="mapouter">
                    <div className="gmap_canvas">
                        <iframe id="gmap_canvas" src="https://maps.google.com/maps?q=melbourne,%20Australia&t=&z=15&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" title="title2"></iframe>
                    </div>
                </div>
            </div>

            {/* <!--=====================================--> */}
        </div>
    )
}

export default ContactUsContent
