import React, { useState, useEffect } from 'react';
import TestimonialContentText from './TestimonialContentText';
import Swiper from 'swiper';

const TestimonialSlideText = ({ testimonials }) => {
  const [swiper, setSwiper] = useState(null);

  useEffect(() => {
    if (testimonials.length > 0) {
      const swiperInstance = new Swiper('.testimonial-activation', {
        loop: true,
        slidesPerView: 1,
        spaceBetween: 30,
        navigation: {
          nextEl: '.swiper-btn-nxt',
          prevEl: '.swiper-btn-prv',
        },
      });
      setSwiper(swiperInstance);
    }
  }, [testimonials]);

  const handleNextClick = () => {
    if (swiper) {
      swiper.slideNext();
    }
  };

  const handlePrevClick = () => {
    if (swiper) {
      swiper.slidePrev();
    }
  };

  return (
    <div className="testimonial-activation swiper">
      <div className="swiper-wrapper">
        {testimonials.map((testimonial, index) => (
          <div key={index} className="swiper-slide">
            <TestimonialContentText
              content={testimonial.content}
              author={{
                name: testimonial.author.name,
                image: testimonial.author.image,
              }}
              subtitle={testimonial.author.subtitle}
              rating={testimonial.rating}
            />
          </div>
        ))}
      </div>
      <div className="swiper-navigation" style={{ display: 'flex', justifyContent: 'center' }}>
        <button className="swiper-btn-nxt" style={{ border: 'none' }} onClick={handleNextClick}>
          <i className="icon-west"></i>
        </button>
        <button className="swiper-btn-prv" style={{ border: 'none' }} onClick={handlePrevClick}>
          <i className="icon-east"></i>
        </button>
      </div>
    </div>
  );
};

export default TestimonialSlideText;