import React from 'react';
import GoogleMap from './GoogleMap';
import Banner from './Banner';
import FormInterviewQuestions from './FormInterviewQuestions';



function InterviewQuestionsContent() {

  return (
    <div>
    {/* <!--=====================================-->
    <!--=       Breadcrumb Area Start      =-->
    <!--=====================================--> */}
       <Banner/>

        {/* <!--=====================================-->
        <!--=       Contact Me Area Start       =-->
        <!--=====================================--> */}
        <section className="contact-us-area">
            <div className="container">
                <div className="row g-5">

                    <div className="col-lg-2"></div>
                    <div className="col-lg-8">
                        <div className="contact-form form-style-2">
                            <div className="section-title">
                                <h4 className="title">Interview questions</h4>
                                <p>Do you have some interview questions to submit?</p>
                            </div>
                            <FormInterviewQuestions/>
                            <ul className="shape-group">
                                <li className="shape-1 scene"><img data-depth="1" src="assets/images/about/shape-13.png" alt="Shape"/></li>
                                <li className="shape-2 scene"><img data-depth="-1" src="assets/images/counterup/shape-02.png" alt="Shape"/></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-2"></div>
                </div>
            </div>
        </section>
        {/* <!--=====================================-->
        <!--=      Google Map Area Start        =-->
        <!--=====================================--> */}
        <GoogleMap/>
        {/* <!--=====================================--> */}
    </div>
  )
}

export default InterviewQuestionsContent
