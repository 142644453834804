import React from 'react'

function ComingSoonContent() {
  return (
    <div id="main-wrapper" className="main-wrapper">

    {/* <!--=====================================-->
    <!--=     Comming Soon Area Start       =-->
    <!--=====================================--> */}
        <section className="coming-soon-page-area ">
            <div className="container">
                <div className="coming-soon-content">
                    <h1 className="title">Coming Soon</h1>
                    <div className="coming-countdown"></div>
                    <div className="input-group">
                        <input type="email" className="form-control" placeholder="Enter your email address"/>
                        <button className="edu-btn btn-medium" type="button">Subscribe <i className="icon-4"></i></button>
                    </div>
                </div>
            </div>
            <ul className="shape-group">
                <li className="shape-1 scene">
                    <img data-depth="2" src="assets/images/others/shape-22.png" alt="Shape"/>
                </li>
                <li className="shape-2 scene">
                    <img data-depth="-2" src="assets/images/others/shape-23.png" alt="Shape"/>
                </li>
                <li className="shape-3">
                    <img className="rotateit" src="assets/images/others/shape-24.png" alt="Shape"/>
                </li>
                <li className="shape-4"></li>
            </ul>
        </section>

    </div>
  )
}

export default ComingSoonContent
