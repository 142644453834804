import React from 'react'

import SupportContent from '../../components/pages/Support/SupportContent';

function Support() {
  return (
  
   
      <SupportContent/>
     
  )
}

export default Support
