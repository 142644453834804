import React from 'react'
import NextSessionContent from '../../components/pages/Next-session/NextSessionContent'

function NextSession() {
  
  return (
    <NextSessionContent fileUrl="/assets/vid.mp4"/>  
  )
}

export default NextSession
