import React from 'react'


import FaqContent from '../../components/pages/FAQ/FaqContent';
function FAQ() {
  return (
    <div>
      
        <FaqContent/>
       
       
    </div>
  )
}

export default FAQ
