import React from 'react';
import BrandGrid from './BrandGrid';

const BrandArea = () => {
  const brandImages = [
    { src: '/assets/images/brand/brand-01.png', alt: 'Brand Logo' },
    { src: '/assets/images/brand/brand-02.png', alt: 'Brand Logo' },
    { src: '/assets/images/brand/brand-03.png', alt: 'Brand Logo' },
    { src: '/assets/images/brand/brand-04.png', alt: 'Brand Logo' },
    { src: '/assets/images/brand/brand-08-1.png', alt: 'Brand Logo' },
    { src: '/assets/images/brand/brand-08-2.png', alt: 'Brand Logo' },
    { src: '/assets/images/brand/brand-08-3.png', alt: 'Brand Logo' },
    { src: '/assets/images/brand/brand-08-4.png', alt: 'Brand Logo' },
    { src: '/assets/images/brand/brand-05.png', alt: 'Brand Logo' },
    { src: '/assets/images/brand/brand-06.png', alt: 'Brand Logo' },
    { src: '/assets/images/brand/brand-07.png', alt: 'Brand Logo' },
    { src: '/assets/images/brand/brand-08.png', alt: 'Brand Logo' },
    { src: '/assets/images/brand/brand-08-5.png', alt: 'Brand Logo' },
    { src: '/assets/images/brand/brand-09-1.png', alt: 'Brand Logo' },
    { src: '/assets/images/brand/brand-09-6.png', alt: 'Brand Logo' },
    { src: '/assets/images/brand/brand-09-2.png', alt: 'Brand Logo' },
    { src: '/assets/images/brand/brand-09-3.png', alt: 'Brand Logo' },
    { src: '/assets/images/brand/brand-09-4.png', alt: 'Brand Logo' },
    { src: '/assets/images/brand/brand-09-5.png', alt: 'Brand Logo' },
    { src: '/assets/images/brand/brand-09.png', alt: 'Brand Logo' },
    { src: '/assets/images/brand/brand-09-7.png', alt: 'Brand Logo' },
    { src: '/assets/images/brand/brand-09-8.png', alt: 'Brand Logo' },
    { src: '/assets/images/brand/brand-09-9.png', alt: 'Brand Logo' },
    { src: '/assets/images/brand/brand-10.png', alt: 'Brand Logo' },
    { src: '/assets/images/brand/brand-10-1.png', alt: 'Brand Logo' },
    { src: '/assets/images/brand/brand-10-2.png', alt: 'Brand Logo' },
    { src: '/assets/images/brand/brand-10-3.png', alt: 'Brand Logo' },
    { src: '/assets/images/brand/brand-10-4.png', alt: 'Brand Logo' },
    { src: '/assets/images/brand/brand-10-5.png', alt: 'Brand Logo' },
    { src: '/assets/images/brand/brand-10-6.png', alt: 'Brand Logo' },
    { src: '/assets/images/brand/brand-10-7.png', alt: 'Brand Logo' },
    { src: '/assets/images/brand/brand-10-8.png', alt: 'Brand Logo' },
    { src: '/assets/images/brand/brand-10-9.png', alt: 'Brand Logo' },
    { src: '/assets/images/brand/brand-11.png', alt: 'Brand Logo' },
    { src: '/assets/images/brand/brand-11-1.png', alt: 'Brand Logo' },
    
    
    
    
  ];

  return (
    <div className="edu-brand-area brand-area-4 gap-lg-bottom-equal">
      <div className="container">
        <div className="brand-grid-wrap brand-style-2">
          {brandImages.map((brand, index) => (
            <BrandGrid key={index} imgSrc={brand.src} altText={brand.alt} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default BrandArea;