const baseUrl = 'https://jsonplaceholder.typicode.com/users';
const authenticationBaseUrl = process.env.REACT_APP_AUTHENTICATION_SERVICE;

export const accountingBaseUrlSecure = process.env.REACT_APP_ACCOUNTING_SERVICE + '/api/v1/secure/';
export const MEDIA_URL = authenticationBaseUrl + "/uploads/";

const lmsBaseURL = process.env.REACT_APP_LMS_COURSE_SERVICE;
const lmsApiURL = process.env.REACT_APP_LMS_COURSE_SERVICE + '/api';

const BackendUrls = {
    baseUrl: baseUrl,
    uploadFileURL: `${lmsApiURL}/upload`,
    lmsBaseURL: lmsBaseURL,
    lmsApiURL: lmsApiURL,

    USER: {       
        CONFIRM_DISOUNT: accountingBaseUrlSecure + "user/discount-intent/confirm",
        SUCCESS_DISOUNT: accountingBaseUrlSecure + "user/information-about-success-subcription",
        ALL_USER:lmsApiURL+ "/user/list",
        ALL: lmsApiURL + "/user/list",
        CREATE: lmsApiURL + "/user/create",
        UPDATE: lmsApiURL + "/user/edit",
        DELETEE: lmsApiURL + "/user/delete",
        LOGIN: lmsApiURL + "/login",
        DELETE:lmsApiURL+ "/enrollments/delete",
    },

  
}
export default BackendUrls;