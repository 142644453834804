import React from 'react';

function TestimonialSlide({ imageUrl }) {
  return (
    <div className="testimonial-slide">
      <img decoding="async" src={imageUrl} className="attachment-large size-large wp-image-63962" alt="" />
    </div>
  );
}

export default TestimonialSlide;