
import React from 'react'

import CompaniesStudentsWorkedContent from '../../components/pages/CompaniesStudentsWorked/CompaniesStudentsWorkedContent';

function CompaniesStudentsWorked() {
  return (
    <div>
     
        <CompaniesStudentsWorkedContent/>
        
    </div>
  )
}

export default CompaniesStudentsWorked