import React, { useState } from 'react';
import Accordion from './Accordion';

const FAQAccordion = () => {
  const [items] = useState([
    {
      title: 'How can I contact a school directly?',
      content: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt labore et dolore magna aliqua enim ad minim veniam quis nostrud exercitation ullamco qui laboris nis aliquip commodo consequat.',
      isExpanded: true
    },
    {
      title: 'How do I find a school where I want to study?',
      content: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt labore et dolore magna aliqua enim ad minim veniam quis nostrud exercitation ullamco qui laboris nis aliquip commodo consequat.',
      isExpanded: false
    },
    {
        title: 'Where should I study abroad?',
        content: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt labore et dolore magna aliqua enim ad minim veniam quis nostrud exercitation ullamco qui laboris nis aliquip commodo consequat.',
        isExpanded: false
      },
      {
        title: ' Where can I find information on private companies?',
        content: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt labore et dolore magna aliqua enim ad minim veniam quis nostrud exercitation ullamco qui laboris nis aliquip commodo consequat.',
        isExpanded: false
      },
      {
        title: ' How do I find a study abroad program?',
        content: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt labore et dolore magna aliqua enim ad minim veniam quis nostrud exercitation ullamco qui laboris nis aliquip commodo consequat.',
        isExpanded: false
      },
      {
        title: ' Am I eligible for admission?',
        content: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt labore et dolore magna aliqua enim ad minim veniam quis nostrud exercitation ullamco qui laboris nis aliquip commodo consequat.',
        isExpanded: false
      },
      {
        title: '  What kind of support does EduBlink provide?',
        content: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt labore et dolore magna aliqua enim ad minim veniam quis nostrud exercitation ullamco qui laboris nis aliquip commodo consequat.',
        isExpanded: false
      },
    // Ajoutez les autres éléments de l'accordéon ici
  ]);

  return (
    <div className="faq-accordion">
      <Accordion items={items} />
    </div>
  );
};

export default FAQAccordion;