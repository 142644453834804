import React from 'react'
import ComingSoonContent from './ComingSoonContent'
import ComingSoonProgress from './ComingSoonProgress'
function ComingSoon() {
  return (
    <div id="main-wrapper" className="main-wrapper">
      <ComingSoonContent/>
      <ComingSoonProgress/>
    </div>
  )
}

export default ComingSoon
