import React from 'react'

function GoogleMap() {
  return (
    <div className="google-map-area">
      <div className="mapouter">
          <div className="gmap_canvas">
              <iframe id="gmap_canvas" title="interview question" src="https://maps.google.com/maps?q=melbourne,%20Australia&t=&z=15&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
          </div>
      </div>
    </div>
  )
}

export default GoogleMap
