import React from 'react';
import TestimonialSlideText from './TestimonialSlideText';
function TestimonialText() {
  const testimonials = [
    {
      content:
        'During the peak of the pandemic last year (2020) I got laid off from my job in O&G industry. A friend told me about Utrains and my story in IT began. This program is very comprehensive and covers all the aspects of software delivery. After intense studying of the class materials for 2-3 months, I landed my first job for 100k. If you want to change your story financially or looking to become a Cloud or DevOps guru, Utrains is the place to be!!!!',
      author: {
        name: 'SAM',
        title: 'Devops Engineer USA',
        image: 'testimonial-01.png',
      },
    },
    {
      content:
        'Good morning my dear Agnes This is Nelly. I just wanted to give you a feed back from our first two weeks of class. I am so humble and blessed to be part of it. The thing that amaze me with prof, is that we pray before we start class since then the Holy Spirit is really guiding us. The way prof teaches is like he open your brain and put all the knowledge there. We thank you for his life and you as well being a big support system for him. May God continue to bless him with more wisdom. I am so confident majority of the class will get a job in no time after we done. Thank you. I feel like an engineer already 🙌🙏',
      author: {
        name: 'ALI',
        title: 'A STUDENT',
        image: 'testimonial-01.png',
      },
    },
    {
      content:
        'Before I got my first IT job, I couldn\'t imagine that I could earn more than $40k a year, but after this program, I\'m happy to say that I earn $120,000 a year as my starting salary. I really appreciated the course structures and the lessons. The instructors are highly professional and they know how to transfer their knowledge',
      author: {
        name: 'DENIS',
        title: 'Devops Engineer UK',
        image: 'testimonial-03.png',
      },
    },
    {
      content:
        'All I can say is believe in yourself that you can do it and you will do it. You can make it by Hardwork, patience , perseverance, passion, and persistence. Fail 10 times rise 11 you will still make it, never lose the sight of a gazelle for a dashing squirrel. Above all keep God 1st.',
      author: {
        name: 'ELLY',
        title: 'Tech. Consultant & Cloud engineer USA',
        image: 'testimonial-04.png',
      },
    },
  ];

  return (
    <div className="testimonial-area-4 section-gap-large" style={{ padding: '40px 0 70px' }}>
    <div className="container edublink-animated-shape">
      <div className="row justify-content-center">
        <div className="col-lg-6">
          <div className="section-title section-center" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
            <span className="pre-title">Testimonials</span>
            <h2 className="title">What Our Students Have To Say</h2>
            <span className="shape-line">
              <i className="icon-19"></i>
            </span>
            <p>
              We are happy to see more than a thousand happy students expressing their <br /> testimonies after completing our program. Are you next?
            </p>
          </div>
        </div>
      </div>
      <TestimonialSlideText testimonials={testimonials} />
      <ul className="shape-group">
        <li className="shape-1 scene" data-sal-delay="200" data-sal="fade" data-sal-duration="1000">
          <img data-depth="1.4" src="assets/images/about/shape-30.png" alt="Shape" />
        </li>
        <li className="shape-2 scene" data-sal-delay="200" data-sal="fade" data-sal-duration="1000">
          <img data-depth="-1.4" src="assets/images/about/shape-25.png" alt="Shape" />
        </li>
        <li className="shape-3" data-sal-delay="200" data-sal="fade" data-sal-duration="1000">
          <img
            className="d-block-shape-light"
            data-depth="2"
            src="assets/images/others/map-shape-3.png"
            alt="Shape"
          />
          <img
            className="d-none-shape-dark"
            data-depth="2"
            src="assets/images/others/dark-map-2-shape-3.png"
            alt="Shape"
          />
        </li>
      </ul>
    </div>
  </div>
  );
}

export default TestimonialText;