import React from 'react';
import TestimonialTable1 from './TestimonialTable1';
import TestimonialTable2 from './TestimonialTable2';
import TestimonialTable3 from './TestimonialTable3';
import TestimonialTable4 from './TestimonialTable4';
import TestimonialTable5 from './TestimonialTable5';
import TestimonialTable6 from './TestimonialTable6';

const TabContent = ({ activeTab }) => {
    const getTabTitle = (tabId) => {
      switch (tabId) {
        case 'gn-ques':
          return 'Cloud Devops Engineer';
        case 'rg-ques':
          return 'Devops Engineer';
        case 'ad-ques':
          return 'Sr Devops Engineer';
        case 'com-policy':
          return 'Software Engineer';
        case 'pay-option':
          return 'System Engineer';
        case 'terms-condition':
          return 'Site Reliability Engineer';
        default:
          return '';
      }
    };
  
    const getTabContent = (tabId) => {
      switch (tabId) {
        case 'gn-ques':
          return <TestimonialTable1 />;
        case 'rg-ques':
          return <TestimonialTable2 />;
        case 'ad-ques':
          return <TestimonialTable3 />;
        case 'com-policy':
          return <TestimonialTable4 />;
        case 'pay-option':
          return <TestimonialTable5 />;
        case 'terms-condition':
          return <TestimonialTable6 />;
        default:
          return null;
      }
    };
  
    return (
      <div className={`tab-pane fade ${activeTab === 'gn-ques' || activeTab === 'rg-ques' || activeTab === 'com-policy' || activeTab === 'ad-ques' || activeTab === 'pay-option' ? 'show active' : ''}`} id={activeTab} role="tabpanel">
        <div className="faq-accordion">
          <div className="accordion">
            <div className="accordion-item">
              <h5 className="accordion-header">
                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={`#question-${activeTab}`} aria-expanded="true">
                  {getTabTitle(activeTab)}
                </button>
              </h5>
              <div id={`question-${activeTab}`} className="accordion-collapse collapse show" data-bs-parent="#faq-accordion">
                <div className="accordion-body">{getTabContent(activeTab)}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

export default TabContent;