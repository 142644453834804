// TestimonialHeadingVideo.js
import React from 'react';
import TestimonialNavigation from './TestimonialNavigation';

const TestimonialHeadingVideo = ({ preTitle, title, description }) => {
  return (
    <div className="testimonial-heading-area">
      <div className="section-title section-left" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
        <span className="pre-title pre-textsecondary">{preTitle}</span>
        <h2 className="title">{title}</h2>
        <span className="shape-line"><i className="icon-19"></i></span>
        <p>{description}</p>
        <TestimonialNavigation />
      </div>
    </div>
  );
};

export default TestimonialHeadingVideo;