import React from 'react'
import SessionDetails from './SessionDetails'
import SessionAllDetail from './SessionAllDetail'
function CourseDetails() {
  return (
    <section className="edu-section-gap course-details-area edu-section-gap-custom">
        <div className="container">
            <div className="row row--30">
                <div className="col-lg-8">
                    <div className="course-details-content">
                        <ul className="nav nav-tabs scrollmenu nav-next-session-custom" id="myTab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button className="nav-link active" id="installment-tab" data-bs-toggle="tab" data-bs-target="#installment" type="button" role="tab" aria-controls="overview" aria-selected="true">Installment</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" id="overview-tab" data-bs-toggle="tab" data-bs-target="#overview" type="button" role="tab" aria-controls="overview" aria-selected="true">Overview</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" id="carriculam-tab" data-bs-toggle="tab" data-bs-target="#carriculam" type="button" role="tab" aria-controls="carriculam" aria-selected="false">Carriculam</button>
                            </li>
                        </ul>

                        <SessionAllDetail/>
                    </div>
                </div>
                <SessionDetails fileUrl="/assets/vid.mp4"/>
            </div>
        </div>
    </section>
  )
}

export default CourseDetails
