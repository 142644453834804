import { useEffect } from 'react';

const LoadScriptAfterReact = () => {
    useEffect(() => {
        const scriptUrls = [
            `/assets/js/vendor/modernizr.min.js`,
            `/assets/js/vendor/jquery.min.js`,
            `/assets/js/vendor/bootstrap.min.js`,
            `/assets/js/vendor/sal.min.js`,
            `/assets/js/vendor/jquery.waypoints.js`,
            `/assets/js/vendor/backtotop.min.js`,
            `/assets/js/vendor/magnifypopup.min.js`,
            `/assets/js/vendor/jquery.countdown.min.js`,
            `/assets/js/vendor/jQuery.rProgressbar.min.js`,
            `/assets/js/vendor/easypie.js`,
            `/assets/js/vendor/odometer.min.js`,
            `/assets/js/vendor/isotop.min.js`,
            `/assets/js/vendor/imageloaded.min.js`,
            `/assets/js/vendor/lightbox.min.js`,
            `/assets/js/vendor/paralax.min.js`,
            // `/assets/js/vendor/paralax-scroll.min.js`,
            `/assets/js/vendor/jquery-ui.min.js`,
            `/assets/js/vendor/swiper-bundle.min.js`,
            `/assets/js/vendor/svg-inject.min.js`,
            `/assets/js/vendor/vivus.min.js`,
            `/assets/js/vendor/tipped.min.js`,
            `/assets/js/vendor/smooth-scroll.min.js`,
            `/assets/js/vendor/isInViewport.jquery.min.js`,
            `/assets/js/app.js`,
        ];

        scriptUrls.forEach((url) => {
            const script = document.createElement('script');
            script.src = url;
            script.async = false;
            document.body.appendChild(script);
        });

        return () => {
            scriptUrls.forEach((url) => {
                const script = document.querySelector(`script[src="${url}"]`);
                if (script) {
                    document.body.removeChild(script);
                }
            });
        };
    }, []);

    return null; 
};

export default LoadScriptAfterReact;
