
import { Link } from 'react-router-dom'

import SectionJobAlert from './SectionJobAlert'
import TestimonialImage from './TestimonialImage'
import TestimonialText from './TestimonialText'
import TestimonialVideo from './TestimonialVideo';



function TestimonialContent() {
   

    return (
        <div>
            {/* <!--=====================================-->
        <!--=       Hero Banner Area Start      =-->
        <!--=====================================--> */}
            <div className="edu-breadcrumb-area">
                <div className="container">
                    <div className="breadcrumb-inner">
                        <div className="page-title">
                            <h1 className="title">Testimonial</h1>
                        </div>
                        <ul className="edu-breadcrumb">
                            <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                            <li className="separator"><i className="icon-angle-right"></i></li>
                            <li className="breadcrumb-item active" aria-current="page">Testimonial</li>
                        </ul>
                    </div>
                </div>
                <ul className="shape-group">
                    <li className="shape-1">
                        <span></span>
                    </li>
                    <li className="shape-2 scene"><img data-depth="2" src="assets/images/about/shape-13.png" alt="shape" /></li>
                    <li className="shape-3 scene"><img data-depth="-2" src="assets/images/about/shape-15.png" alt="shape" /></li>
                    <li className="shape-4">
                        <span></span>
                    </li>
                    <li className="shape-5 scene">< img data-depth="2" src="assets/images/about/shape-07.png" alt="shape" /></li>
                </ul>
            </div>
            <TestimonialVideo/>

            {/* <div className="testimonial-area-4">
                <div className="container ">
                    <div className="row g-5">
                         <div className="col-lg-5">
                                <div className="testimonial-heading-area">
                                    <div className="section-title section-left" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                                        <span className="pre-title pre-textsecondary">Testimonials</span>
                                        <h2 className="title">What Our Students Have To Say</h2>
                                        <span className="shape-line"><i className="icon-19"></i></span>
                                        <p>We are happy to see more than a thousand happy students expressing their<br /> testimonies  after completing our program. Are you next?</p>
                                    </div>
                                </div>
                                <div className="swiper-navigation">
                                    <div className="swiper-btn-nxt">
                                        <i className="icon-west"></i>
                                    </div>
                                    <div className="swiper-btn-prv">
                                        <i className="icon-east"></i>
                                    </div>
                                </div><br/>
                            </div>

                        <div className="col-lg-7 edu-order-md-1">
                            <div className="gym-testimonial-activator swiper ">
                                <div className="swiper-wrapper">
                                    <div className="swiper-slide">
                                        <div className="testimonial-slide testimonial-style-2">
                                            <iframe className="popup-icon video-popup-activation" id='video-popup-activation' title="student testimonial" width="100%" height="400px" src="https://www.youtube.com/embed/LjZAG22S_AE?rel=0" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen style={{ borderRadius: '10px' }}></iframe>
                                        </div>
                                    </div>
                                    <div className="swiper-slide">
                                        <div className="testimonial-slide testimonial-style-2">
                                            <iframe className="popup-icon video-popup-activation" id='video-popup-activation' title="student testimonial" width="100%" height="400px" src="https://www.youtube.com/embed/LjZAG22S_AE?rel=0" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen style={{ borderRadius: '10px' }}></iframe>
                                        </div>
                                    </div>
                                    <div className="swiper-slide">
                                        <div className="testimonial-slide testimonial-style-2">
                                            <iframe className="popup-icon video-popup-activation" id='video-popup-activation' title="student testimonial" width="100%" height="400px" src="https://www.youtube.com/embed/LjZAG22S_AE?rel=0" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen style={{ borderRadius: '10px' }}></iframe>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
                <ul className="shape-group">
                    <li className="shape-1 scene">
                        <img data-depth="2" src="assets/images/others/shape-18.png" alt="Shape" />
                    </li>
                    <li className="shape-2">
                        <img src="assets/images/others/map-shape-3.png" alt="Shape" />
                    </li>
                </ul>
            </div> */}
            {/* End Testimonial video Area   */}
            <TestimonialText/>
            {/* <div className="testimonial-area-4 section-gap-large" style={{ padding: '40px 0 70px'}}>
                <div className="container edublink-animated-shape">
                    <div className="row justify-content-center">
                        <div className="col-lg-6">
                            <div className="section-title section-center" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                                <span className="pre-title">Testimonials</span>
                                <h2 className="title">What Our Students <br /> Have To Say</h2>
                                <span className="shape-line"><i className="icon-19"></i></span>
                                <p>We are happy to see more than a thousand happy students expressing their<br /> testimonies  after completing our program. Are you next?</p>
                            </div>
                        </div>
                    </div>
                    <div className="testimonial-activation swiper" ref={(node) => { if (node) setSwiper(node.swiper); }}>
                        <div className="swiper-wrapper">
                            <div className="swiper-slide">
                                <div className="testimonial-slide">
                                    <div className="content">

                                        <p>During the peak of the pandemic last year (2020) I got laid off from my job in O&G industry.
                                            A friend told me about Utrains and my story in IT began. This program is very comprehensive and covers all the aspects of software delivery.
                                            After intense studying of the class materials for 2-3 months, I landed my first job for 100k.
                                            If you want to change your story financially or looking to become a Cloud or DevOps guru, Utrains is the place to be!!!!</p>
                                        <div className="rating-icon">
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                        </div>
                                    </div>
                                    <div className="author-info">
                                        <div className="thumb">
                                            <img src="assets/images/testimonial/testimonial-01.png" alt="Testimonial" />
                                        </div>
                                        <div className="info">
                                            <h5 className="title">	SAM</h5>
                                            <span className="subtitle">	Devops Engineer USA</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div className="testimonial-slide">
                                    <div className="content">

                                        <p>Good morning my dear Agnes This is Nelly. I just wanted to give you a feed back from our first two weeks of class. I am so humble and blessed to be part of it. The thing that amaze me with prof, is that we pray before we start class since then the Holy Spirit is really guiding us. The way prof teaches is like he open your brain and put all the knowledge there. We thank you for his life and you as well being a big support system for him. May God continue to bless him with more wisdom. I am so confident majority of the class will get a job in no time after we done. Thank you. I feel like an engineer already 🙌🙏</p>
                                        <div className="rating-icon">
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                        </div>
                                    </div>
                                    <div className="author-info">
                                        <div className="thumb">
                                            <img src="assets/images/testimonial/testimonial-02.png" alt="Testimonial" />
                                        </div>
                                        <div className="info">
                                            <h5 className="title">	A STUDENT</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div className="testimonial-slide">
                                    <div className="content">

                                        <p>Before I got my first IT job, I could'nt imagine that I could earn more than $40k a year, but after this program, I'm happy to say that I earn $120 a year as my starting salary. I really appreciated the course structures and the lessons. The instructors are highly professional and they know how to transfer their knowledge.</p>
                                        <div className="rating-icon">
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                        </div>
                                    </div>
                                    <div className="author-info">
                                        <div className="thumb">
                                            <img src="assets/images/testimonial/testimonial-03.png" alt="Testimonial" />
                                        </div>
                                        <div className="info">
                                            <h5 className="title">DENIS</h5>
                                            <span className="subtitle">Devops Engineer UK</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div className="testimonial-slide">
                                    <div className="content">

                                        <p> All I can say is believe in yourself that you can do it and you will do it. You can make it by Hardwork, patience , perseverance, passion, and persistence. Fail 10 times rise 11 you will still make it, never lose the sight of a gazelle for a dashing squirrel. Above all keep God 1st.</p>
                                        <div className="rating-icon">
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                        </div>
                                    </div>
                                    <div className="author-info">
                                        <div className="thumb">
                                            <img src="assets/images/testimonial/testimonial-04.png" alt="Testimonial" />
                                        </div>
                                        <div className="info">
                                            <h5 className="title">ELLY</h5>
                                            <span className="subtitle">Tech. Consultant & Cloud engineer USA</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <ul className="shape-group">
                        <li className="shape-1 scene" data-sal-delay="200" data-sal="fade" data-sal-duration="1000">
                            <img data-depth="1.4" src="assets/images/about/shape-30.png" alt="Shape" />
                        </li>
                        <li className="shape-2 scene" data-sal-delay="200" data-sal="fade" data-sal-duration="1000">
                            <img data-depth="-1.4" src="assets/images/about/shape-25.png" alt="Shape" />
                        </li>
                    </ul>
                    
                </div>
               <div className="swiper-navigation" style={{ display: 'flex', justifyContent: 'center' }}>  

                   <button className="swiper-btn-nxt" style={{ border: 'none' }} onClick={handleNextClick }> <i className="icon-west"></i></button>
                    <button className="swiper-btn-prv" style={{ border: 'none' }} onClick={handlePrevClick}> <i className="icon-east"></i> </button>

                </div>  
                <ul className="shape-group">
                    <li className="shape-3" data-sal-delay="200" data-sal="fade" data-sal-duration="1000">
                        <img className="d-block-shape-light" data-depth="2" src="assets/images/others/map-shape-3.png" alt="Shape" />
                        <img className="d-none-shape-dark" data-depth="2" src="assets/images/others/dark-map-2-shape-3.png" alt="Shape" />
                    </li>
                </ul>
               
           
            </div> */}


            {/*Images start */}
               <TestimonialImage/>
                {/* Alert job start */}
<SectionJobAlert/>
               
            </div>
         
            )
}

            export default TestimonialContent
