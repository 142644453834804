import React from 'react';

const Accordion = ({ items }) => {
  return (
    <div className="accordion">
      {items.map((item, index) => (
        <div className="accordion-item" key={index}>
          <h5 className="accordion-header">
            <button
              className={`accordion-button ${item.isExpanded ? '' : 'collapsed'}`}
              type="button"
              data-bs-toggle="collapse"
              data-bs-target={`#question-${index}`}
              aria-expanded={item.isExpanded}
            >
              {item.title}
            </button>
          </h5>
          <div
            id={`question-${index}`}
            className={`accordion-collapse collapse ${item.isExpanded ? 'show' : ''}`}
            data-bs-parent="#faq-accordion"
          >
            <div className="accordion-body">
              <p>{item.content}</p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Accordion;