import { useState, useEffect } from "react";
import { getUser, logout, isAuthenticated } from "../../services/Auth";
import { Link } from "react-router-dom";
import HeaderTop from "./HeaderTop";
import HeaderCategory from "./HeaderCategory";
import routesPath from '../../routes/routesPath';
import Dropdown from "./Dropdown";

// import HeaderTopbar from './HeaderTopbar';


function Header() {

    
    const [isLoggedIn, setIsLoggedIn] = useState(false);
   

    useEffect(() => {
        setIsLoggedIn(isAuthenticated());
    }, []);

    // const handleProfileClick = () => {
    //     setProfileOpen(!profileOpen);
    // };

    return (
        <header className="edu-header header-style-1 header-fullwidth">
            <HeaderTop />
            <div id="edu-sticky-placeholder"></div>

            <div className="header-mainmenu">
                <div className="container-fluid">
                    <div className="header-navbar">
                        <div className="header-brand">
                            <div className="logo">
                                <Link to="/">
                                    <img className="logo-light" src="/assets/images/logo/logo-dark.png" alt="Corporate Logo" />
                                    <img className="logo-dark" src="/assets/images/logo/logo-white.png" alt="Corporate Logo" />
                                </Link>
                            </div>
                            {/* <HeaderCategory /> */}
                        </div>
                        <div className="header-mainnav">
                            <nav className="mainmenu-nav">
                                <ul className="mainmenu">
                                    <li className=""><Link to={routesPath.home}>Home</Link></li>
                                    <li className=""><Link to="/faq">FAQ</Link></li>
                                    <li className=""><Link to="/testimonials">Testimonials</Link></li>
                                    <li className=""><Link to="/feedback">FeedBack</Link></li>
                                    <li className=""><Link to="/contact_us">Contact</Link></li>
                                </ul>
                            </nav>
                        </div>

                        <div className="header-right">
                            {isLoggedIn ? (
                              <Dropdown/>
// <div className="hero">
// <img src="/w/assets/images/user/avatar-2.jpg" alt="user img" className="rounded-circle-user" width="50" height="50" />
// <div>
// <div> 
// <div>
// <div className="flex-grow-1 ms-3">
// <img src="/w/assets/images/user/avatar-2.jpg" alt="user img" className="rounded-circle-user" width="50" height="50" />
//   <h6 className="mb-1">{`${getUser().firstName} ${getUser().lastName}`} 🖖</h6>
//    <span>{getUser().username}</span>
//   </div>
// </div></div></div></div>

//                                 <div class="content">
    
    
//     <div class="container">
//       <div class="row justify-content-center text-center">
//         <div class="col-md-5">
//           <div class="dropdown custom-dropdown show">
//             <a href="/" data-toggle="dropdown" class="d-flex align-items-center dropdown-link text-left" aria-haspopup="true" aria-expanded="true" data-offset="0, 20">
//               <div class="profile-pic mr-3">
//                 <img src="/w/assets/images/user/avatar-2.jpg" alt="Imag"/>
//               </div>
              


//             </a>

//             <div class="dropdown-menu show active" aria-labelledby="dropdownMenuButton" x-placement="bottom-start"style={{
//     position: 'absolute',
//     willChange: 'transform',
//     top: '0px',
//     left: '0px',
//     transform: 'translate3d(0px, 70px, 0px)'
// }}>
//             <div class="profile-pic mr-3">
//                 <img src="/w/assets/images/user/avatar-2.jpg" alt="Imag"/>
//               </div>
//             <div class="profile-info">
//             <h6 className="mb-1">{`${getUser().firstName} ${getUser().lastName}`} 🖖</h6>
//                               <span>{getUser().username}</span>
//               </div>
            
//               <a class="dropdown-item" href="/"> <span class="icon icon-dashboard"></span> User Dashboard</a>
//               <a class="dropdown-item" href="/"><span class="icon icon-mail_outline"></span>Inbox <span class="number">3</span></a>
//               <a class="dropdown-item" href="/"><span class="icon icon-people"></span>Following</a>
//               <a class="dropdown-item" href="/"><span class="icon icon-cog"></span>Setting<span>New</span></a>
//               <button class="dropdown-item" onClick={logout} ><span class="icon icon-sign-out"></span>Log out</button>
              
            



//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   </div>
                                // <HeaderTopbar />
                                // <div className="dropdown">
                     
                                //     <a href="/" className="dropdown-toggle" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                                //         <img src="/w/assets/images/user/avatar-2.jpg" alt="user img" className="rounded-circle" width="50" height="50" />
                                //     </a>
                                //     <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                //         <div className="dropdown-header">
                                //             <h5 className="m-0">Profile</h5>
                                //         </div>
                                //         <div className="dropdown-body">
                                //             <div className="profile-notification-scroll position-relative" style={{ maxHeight: 'calc(100vh - 225px)', overflowY: 'auto' }}>
                                //                 <div className="d-flex mb-1">
                                //                     <div className="flex-shrink-0">
                                //                         <img src="/w/assets/images/user/avatar-2.jpg" alt="user img" className="user-avtar wid-35" />
                                //                     </div>
                                //                     <div className="flex-grow-1 ms-3">
                                //                         <h6 className="mb-1">{`${getUser().firstName} ${getUser().lastName}`} 🖖</h6>
                                //                         <span>{getUser().username}</span>
                                //                     </div>
                                //                 </div>
                                //                 <hr className="border-secondary border-opacity-50" />

                                //                 <p className="text-span">Manage</p>
                                //                 <a href="/" className="dropdown-item">
                                //                     <span>
                                //                         <svg className="pc-icon text-muted me-2">
                                //                             <use xlinkHref="#custom-setting-outline"></use>
                                //                         </svg>
                                //                         <span>Settings</span>
                                //                     </span>
                                //                 </a>
                                //                 <a href="/" className="dropdown-item">
                                //                     <span>
                                //                         <svg className="pc-icon text-muted me-2">
                                //                             <use xlinkHref="#custom-lock-outline"></use>
                                //                         </svg>
                                //                         <span>Change Password</span>
                                //                     </span>
                                //                 </a>
                                //                 <hr className="border-secondary border-opacity-50" />

                                //                 <div className="d-grid mb-3">
                                //                     <button className="btn btn-primary" onClick={logout}>
                                //                         <svg className="pc-icon me-2">
                                //                             <use xlinkHref="#custom-logout-1-outline"></use>
                                //                         </svg>
                                //                         Logout
                                //                     </button>
                                //                 </div>
                                //             </div>
                                //         </div>
                                //     </ul>
                                // </div>

                            ) : (
                                <ul className="header-action utrains-header-action">
                                    <li className="header-btn">
                                        <a href="/w/login?redirect=http://localhost:3000/" className="edu-btn btn-medium" >
                                            Login <i className="icon-4"></i>
                                        </a>
                                    </li>
                                    <li className="header-btn">
                                        <Link to="/signup" className="edu-btn btn-medium">
                                            Signup
                                        </Link>
                                    </li>
                                    <li className="mobile-menu-bar d-block d-xl-none">
                                        <button className="hamberger-button">
                                            <i className="icon-54"></i>
                                        </button>
                                    </li>
                                </ul>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className="popup-mobile-menu">
                <div className="inner">
                    <div className="header-top">
                        <div className="logo">
                            <Link to="/">
                                <img className="logo-light" src="/assets/images/logo/logo-dark.png" alt="Corporate Logo" />
                                <img className="logo-dark" src="/assets/images/logo/logo-white.png" alt="Corporate Logo" />
                            </Link>
                        </div>
                        <div className="close-menu">
                            <button className="close-button">
                                <i className="icon-73"></i>
                            </button>
                        </div>
                    </div>
                    <ul className="mainmenu">
                        <li className=""><Link to="/">Home</Link></li>
                        <li className=""><Link to="/faq">FAQ</Link></li>
                        <li className=""><Link to="/testimonials">Testimonials</Link></li>
                        <li className=""><Link to="/feedback">FeedBack</Link></li>
                        <li className=""><Link to="/contact_us">Contact</Link></li>
                    </ul>
                </div>
            </div>

            <div className="edu-search-popup">
                <div className="content-wrap">
                    <div className="site-logo">
                        <img className="logo-light" src="/assets/images/logo/logo-dark.png" alt="Corporate Logo" />
                        <img className="logo-dark" src="/assets/images/logo/logo-white.png" alt="Corporate Logo" />
                    </div>
                    <div className="close-button">
                        <button className="close-trigger"><i className="icon-73"></i></button>
                    </div>
                    <div className="inner">
                        <form className="search-form" action="#">
                            <input type="text" className="edublink-search-popup-field" placeholder="Search Here..." />
                            <button className="submit-button"><i className="icon-2"></i></button>
                        </form>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Header
