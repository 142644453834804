import React from 'react'
import { Link } from 'react-router-dom'

function SingleCourse() {
  return (
    <div className="col-12 col-xl-4 col-lg-6 col-md-6" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
        <div className="edu-course course-style-5 inline" data-tipped-options="inline: 'inline-tooltip-1'">
            <div className="inner">
                <div className="thumbnail">
                    <Link to="/course_details_one">
                        <img src="/assets/images/course/course-15.jpg" alt="Course Meta"/>
                    </Link>
                </div>
                <div className="content">
                    <div className="course-price price-round">$30</div>
                    <span className="course-level">Cooking</span>
                    <h5 className="title">
                        <Link to="/course_details_one">Healthy Sushi Roll - Japanese Popular Cooking Class</Link>
                    </h5>
                    <div className="course-rating">
                        <div className="rating">
                            <i className="icon-23"></i>
                            <i className="icon-23"></i>
                            <i className="icon-23"></i>
                            <i className="icon-23"></i>
                            <i className="icon-23"></i>
                        </div>
                        <span className="rating-count">(5)</span>
                    </div>
                    <p>Lorem ipsum dolor sit amet consectur elit sed eiusmod ex tempor.</p>
                    <ul className="course-meta">
                        <li><i className="icon-24"></i>15 Lessons</li>
                        <li><i className="icon-25"></i>42 Students</li>
                    </ul>
                </div>
            </div>
        </div>

        <div id="inline-tooltip-1" style={{display:"none"}}>
            <div className="course-layout-five-tooltip-content">
                <div className="content">
                    <span className="course-level">Cooking</span>
                    <h5 className="title">
                        <Link to="/course_details_one">Healthy Sushi Roll - Japanese Popular Cooking Class</Link>
                    </h5>
                    <div className="course-rating">
                        <div className="rating">
                            <i className="icon-23"></i>
                            <i className="icon-23"></i>
                            <i className="icon-23"></i>
                            <i className="icon-23"></i>
                            <i className="icon-23"></i>
                        </div>
                        <span className="rating-count">(5)</span>
                    </div>
                    <ul className="course-meta">
                        <li>15 Lessons</li>
                        <li>35 hrs</li>
                        <li>Beginner</li>
                    </ul>
                    <div className="course-feature">
                        <h6 className="title">What You’ll Learn?</h6>
                        <ul>
                            <li>Professional Japanese cooking from beginners to experts</li>
                            <li>Will be able to cook authentic Italian recipes in their own kitchen</li>
                            <li>Understand the HOW of cooking, before thinking of the WHAT to cook.</li>
                        </ul>
                    </div>
                    <div className="button-group">
                        <Link to="#" className="edu-btn btn-medium">Add to Cart</Link>
                        <Link to="#" className="wishlist-btn btn-outline-dark"><i className="icon-22"></i></Link>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default SingleCourse
