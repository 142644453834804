import React from 'react'

import FeedbackContent from '../../components/pages/Feedback/FeedbackContent';

function Feedback() {
  return (
    <div>
      
        <FeedbackContent/>
       
       
    </div>
  )
}

export default Feedback