import React from 'react';
import TestimonialSlide from './TestimonialSlide';

function TestimonialCarousel() {
  const testimonialImages = [
    'https://utrains.org/wp-content/uploads/2023/08/WhatsApp-Image-2023-06-29-at-23.13.08-1.jpeg',
    'https://utrains.org/wp-content/uploads/2023/08/WhatsApp-Image-2023-07-21-at-08.56.51.jpeg',
    'https://utrains.org/wp-content/uploads/2023/08/WhatsApp-Image-2023-08-03-at-18.17.40.jpeg',
    'https://utrains.org/wp-content/uploads/2023/08/WhatsApp-Image-2023-07-21-at-08.56.51.jpeg',
    'https://utrains.org/wp-content/uploads/2022/10/WhatsApp-Image-2022-09-29-at-03.17.39.jpeg',
    'https://utrains.org/wp-content/uploads/2022/10/WhatsApp-Image-2022-09-29-at-03.17.39.jpeg'
  ];

  return (
    <div className="business-testimonial-activation swiper">
      <div className="swiper-wrapper">
        {testimonialImages.map((imageUrl, index) => (
          <div className="swiper-slide" key={index}>
            <TestimonialSlide imageUrl={imageUrl} />
          </div>
        ))}
      </div>
    </div>
  );
}

export default TestimonialCarousel;