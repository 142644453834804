import React, { useState, useEffect } from "react";

function Time() {
  const calculateTimeLeft = () => {
    const difference = +new Date("2024-12-31") - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60)
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  const formatTime = (time) => {
    return time < 10 ? `0${time}` : time;
  };

  return (
    <div className="time-area">
      <table className="time-table">
          <thead>
            <tr className="table-head">
                <th colSpan={4} className="table-items table-items-head">{timeLeft.days}</th>
                <th></th>
                <th colSpan={4} className="table-items table-items-head">{formatTime(timeLeft.hours)}</th>
                <th></th>
                <th colSpan={4} className="table-items table-items-head">{formatTime(timeLeft.minutes)}</th>
                <th></th>
                <th colSpan={4} className="table-items table-items-head">{formatTime(timeLeft.seconds)}</th>
            </tr>
          </thead>
            <tbody>
              <tr className="days">
                  <td colSpan={4} className="table-items" style={{border: "none"}}>Days</td>
                  <td style={{border: "none"}}></td>
                  <td colSpan={4} className="table-items" style={{border: "none"}}>Hours</td>
                  <td style={{border: "none"}}></td>
                  <td colSpan={4} className="table-items" style={{border: "none"}}>Minutes</td>
                  <td style={{border: "none"}}></td>
                  <td colSpan={4} className="table-items" style={{border: "none"}}>Secondes</td>
              </tr>
            </tbody>
        </table>
    </div>
  );
}

export default Time;
