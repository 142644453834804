import React from 'react'

function CourseDetails() {

  return (
    <div>
        <div className="edu-breadcrumb-area">
            <div className="container">
                <div className="breadcrumb-inner">
                    <div className="page-title">
                        <h1 className="title">Available Courses</h1>
                    </div>
                    <ul className="edu-breadcrumb">
                        <li className="breadcrumb-item"><a href="index.html">Home</a></li>
                        <li className="separator"><i className="icon-angle-right"></i></li>
                        <li className="breadcrumb-item"><a href="#!">Courses</a></li>
                        
                    </ul>
                </div>
            </div>
            <ul className="shape-group">
                <li className="shape-1">
                    <span></span>
                </li>
                <li className="shape-2 scene"><img data-depth="2" src="assets/images/about/shape-13.png" alt="shape"/></li>
                <li className="shape-3 scene"><img data-depth="-2" src="assets/images/about/shape-15.png" alt="shape"/></li>
                <li className="shape-4">
                    <span></span>
                </li>
                <li className="shape-5 scene"><img data-depth="2" src="assets/images/about/shape-07.png" alt="shape"/></li>
            </ul>
        </div>

        <div className="edu-course-area course-area-1 gap-tb-text">
            <div className="container">


                <div className="edu-sorting-area">
                    <div className="sorting-left">
                        <h6 className="showing-text">We found <span>71</span> courses available for you</h6>
                    </div>
                    <div className="sorting-right">
                        {/* <div className="layout-switcher">
                            <label>Grid</label>
                            <ul className="switcher-btn">
                                <li><a href="course-one.html" className="active"><i className="icon-53"></i></a></li>
                                <li><a href="course-four.html" className=""><i className="icon-54"></i></a></li>
                            </ul>
                        </div> */}
                        <div className="edu-sorting">
                            <div className="icon"><i className="icon-55"></i></div>
                            <select className="edu-select">
                                <option>Filters</option>
                                <option>Courses</option>
                                <option>Opened Sessions</option>
                                <option>Closed Sessions</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div className="row g-5">
                    {/* <!-- Start Single Course  --> */}
                    <div className="col-md-6 col-lg-4 col-xl-3" data-sal-delay="100" data-sal="slide-up" data-sal-duration="800">
                        <div className="edu-course course-style-1 course-box-shadow hover-button-bg-white">
                            <div className="inner">
                                <div className="thumbnail">
                                    <a href="/courses/details/marche-2024">
                                        <img src="assets/images/course/course-07.jpg" alt="Course Meta"/>
                                    </a>
                                    <div className="time-top">
                                        <span className="duration"><i className="icon-61"></i>Course</span>
                                    </div>
                                </div>
                                <div className="content">
                                    <span className="course-level">Beginner</span>
                                    <h6 className="title">
                                        <a href="#!">The Complete Camtasia Course for Content Creators</a>
                                    </h6>
                                    <div className="course-rating">
                                        <div className="rating">
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                        </div>
                                        <span className="rating-count">(5.0 /9 Rating)</span>
                                    </div>
                                    <div className="course-price">$49.00</div>
                                    <ul className="course-meta">
                                        <li><i className="icon-24"></i>15 Lessons</li>
                                        <li><i className="icon-25"></i>31 Students</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="course-hover-content-wrapper">
                                <button className="wishlist-btn"><i className="icon-22"></i></button>
                            </div>
                            <div className="course-hover-content-wrapper">
                                <button className="wishlist-btn"><i className="icon-22"></i></button>
                            </div>
                            <div className="course-hover-content">
                                <div className="content">
                                    <button className="wishlist-btn"><i className="icon-22"></i></button>
                                    <span className="course-level">Advanced</span>
                                    <h6 className="title">
                                        <a href="/courses/details/marche-2024">The Complete Camtasia Course for Content Creators</a>
                                    </h6>
                                    <div className="course-rating">
                                        <div className="rating">
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                        </div>
                                        <span className="rating-count">(5.0 /9 Rating)</span>
                                    </div>
                                    <div className="course-price">$49.00</div>
                                    <p>Lorem ipsum dolor sit amet consectur adipiscing elit sed eiusmod tempor.</p>
                                    <ul className="course-meta">
                                        <li><i className="icon-24"></i>15 Lessons</li>
                                        <li><i className="icon-25"></i>31 Students</li>
                                    </ul>
                                    <a href="/courses/details/marche-2024" className="edu-btn btn-secondary btn-small">Enrolled <i className="icon-4"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- End Single Course  --> */}
                    {/* <!-- Start Single Course  --> */}
                    <div className="col-md-6 col-lg-4 col-xl-3" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                        <div className="edu-course course-style-1 course-box-shadow hover-button-bg-white">
                            <div className="inner">
                                <div className="thumbnail">
                                    <a href="/courses/details/marche-2024">
                                        <img src="assets/images/course/course-04.jpg" alt="Course Meta"/>
                                    </a>
                                    <div className="time-top">
                                        <span className="duration"><i className="icon-61"></i>Course</span>
                                    </div>
                                </div>
                                <div className="content">
                                    <span className="course-level">Intermediate</span>
                                    <h6 className="title">
                                        <a href="#!">Starting SEO as your Home Based Business</a>
                                    </h6>
                                    <div className="course-rating">
                                        <div className="rating">
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                        </div>
                                        <span className="rating-count">(4.9 /7 Rating)</span>
                                    </div>
                                    <div className="course-price">$35.00</div>
                                    <ul className="course-meta">
                                        <li><i className="icon-24"></i>74 Lessons</li>
                                        <li><i className="icon-25"></i>20 Students</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="course-hover-content-wrapper">
                                <button className="wishlist-btn"><i className="icon-22"></i></button>
                            </div>
                            <div className="course-hover-content">
                                <div className="content">
                                    <button className="wishlist-btn"><i className="icon-22"></i></button>
                                    <span className="course-level">Intermediate</span>
                                    <h6 className="title">
                                        <a href="/courses/details/marche-2024">Starting SEO as your Home Based Business</a>
                                    </h6>
                                    <div className="course-rating">
                                        <div className="rating">
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                        </div>
                                        <span className="rating-count">(4.9 /7 Rating)</span>
                                    </div>
                                    <div className="course-price">$35.00</div>
                                    <p>Lorem ipsum dolor sit amet consectur adipiscing elit sed eiusmod tempor.</p>
                                    <ul className="course-meta">
                                        <li><i className="icon-24"></i>74 Lessons</li>
                                        <li><i className="icon-25"></i>20 Students</li>
                                    </ul>
                                    <a href="/courses/details/marche-2024" className="edu-btn btn-secondary btn-small">Enrolled <i className="icon-4"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- End Single Course  --> */}
                    {/* <!-- Start Single Course  --> */}
                    <div className="col-md-6 col-lg-4 col-xl-3" data-sal-delay="200" data-sal="slide-up" data-sal-duration="800">
                        <div className="edu-course course-style-1 course-box-shadow hover-button-bg-white">
                            <div className="inner">
                                <div className="thumbnail">
                                    <a href="/courses/details/marche-2024">
                                        <img src="assets/images/course/course-05.jpg" alt="Course Meta"/>
                                    </a>
                                    <div className="time-top">
                                        <span className="duration"><i className="icon-61"></i>Session</span>
                                    </div>
                                </div>
                                <div className="content">
                                    <span className="course-level">Beginner</span>
                                    <h6 className="title">
                                        <a href="#!">Building A Better World One Student At A Time</a>
                                    </h6>
                                    <div className="course-rating">
                                        <div className="rating">
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                        </div>
                                        <span className="rating-count">(5.0 /7 Rating)</span>
                                    </div>
                                    <div className="course-price">$29.00</div>
                                    <ul className="course-meta">
                                        <li><i className="icon-24"></i>8 Lessons</li>
                                        <li><i className="icon-25"></i>20 Students</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="course-hover-content-wrapper">
                                <button className="wishlist-btn"><i className="icon-22"></i></button>
                            </div>
                            <div className="course-hover-content">
                                <div className="content">
                                    <button className="wishlist-btn"><i className="icon-22"></i></button>
                                    <span className="course-level">Beginner</span>
                                    <h6 className="title">
                                        <a href="/courses/details/marche-2024">Building A Better World One Student At A Time</a>
                                    </h6>
                                    <div className="course-rating">
                                        <div className="rating">
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                        </div>
                                        <span className="rating-count">(5.0 /7 Rating)</span>
                                    </div>
                                    <div className="course-price">$29.00</div>
                                    <p>Lorem ipsum dolor sit amet consectur adipiscing elit sed eiusmod tempor.</p>
                                    <ul className="course-meta">
                                        <li><i className="icon-24"></i>8 Lessons</li>
                                        <li><i className="icon-25"></i>20 Students</li>
                                    </ul>
                                    <a href="/courses/details/marche-2024" className="edu-btn btn-secondary btn-small">Enrolled <i className="icon-4"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- End Single Course  --> */}
                    {/* <!-- Start Single Course  --> */}
                    <div className="col-md-6 col-lg-4 col-xl-3" data-sal-delay="250" data-sal="slide-up" data-sal-duration="800">
                        <div className="edu-course course-style-1 course-box-shadow hover-button-bg-white">
                            <div className="inner">
                                <div className="thumbnail">
                                    <a href="/courses/details/marche-2024">
                                        <img src="assets/images/course/course-06.jpg" alt="Course Meta"/>
                                    </a>
                                    <div className="time-top">
                                        <span className="duration"><i className="icon-61"></i>Session</span>
                                    </div>
                                </div>
                                <div className="content">
                                    <span className="course-level">Beginner</span>
                                    <h6 className="title">
                                        <a href="#!">Java Programming MasterclassName for Software Developers</a>
                                    </h6>
                                    <div className="course-rating">
                                        <div className="rating">
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                        </div>
                                        <span className="rating-count">(5.0 /18 Rating)</span>
                                    </div>
                                    <div className="course-price">$19.00</div>
                                    <ul className="course-meta">
                                        <li><i className="icon-24"></i>24 Lessons</li>
                                        <li><i className="icon-25"></i>95 Students</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="course-hover-content-wrapper">
                                <button className="wishlist-btn"><i className="icon-22"></i></button>
                            </div>
                            <div className="course-hover-content">
                                <div className="content">
                                    <button className="wishlist-btn"><i className="icon-22"></i></button>
                                    <span className="course-level">Beginner</span>
                                    <h6 className="title">
                                        <a href="/courses/details/marche-2024">Java Programming MasterclassName for Software Developers</a>
                                    </h6>
                                    <div className="course-rating">
                                        <div className="rating">
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                        </div>
                                        <span className="rating-count">(5.0 /18 Rating)</span>
                                    </div>
                                    <div className="course-price">$19.00</div>
                                    <p>Lorem ipsum dolor sit amet consectur adipiscing elit sed eiusmod tempor.</p>
                                    <ul className="course-meta">
                                        <li><i className="icon-24"></i>24 Lessons</li>
                                        <li><i className="icon-25"></i>95 Students</li>
                                    </ul>
                                    <a href="/courses/details/marche-2024" className="edu-btn btn-secondary btn-small">Enrolled <i className="icon-4"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- End Single Course  --> */}
                    {/* <!-- Start Single Course  --> */}
                    <div className="col-md-6 col-lg-4 col-xl-3" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                        <div className="edu-course course-style-1 course-box-shadow hover-button-bg-white">
                            <div className="inner">
                                <div className="thumbnail">
                                    <a href="/courses/details/marche-2024">
                                        <img src="assets/images/course/course-24.jpg" alt="Course Meta"/>
                                    </a>
                                    <div className="time-top">
                                        <span className="duration"><i className="icon-61"></i>Course</span>
                                    </div>
                                </div>
                                <div className="content">
                                    <span className="course-level">Beginner</span>
                                    <h6 className="title">
                                        <a href="#!">Angular & NodeJS - The MEAN Stack Guide [2024 Edition]</a>
                                    </h6>
                                    <div className="course-rating">
                                        <div className="rating">
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                        </div>
                                        <span className="rating-count">(5.0 /7 Rating)</span>
                                    </div>
                                    <div className="course-price">$29.00</div>
                                    <ul className="course-meta">
                                        <li><i className="icon-24"></i>8 Lessons</li>
                                        <li><i className="icon-25"></i>20 Students</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="course-hover-content-wrapper">
                                <button className="wishlist-btn"><i className="icon-22"></i></button>
                            </div>
                            <div className="course-hover-content">
                                <div className="content">
                                    <button className="wishlist-btn"><i className="icon-22"></i></button>
                                    <span className="course-level">Beginner</span>
                                    <h6 className="title">
                                        <a href="/courses/details/marche-2024">Angular & NodeJS - The MEAN Stack Guide [2024 Edition]</a>
                                    </h6>
                                    <div className="course-rating">
                                        <div className="rating">
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                        </div>
                                        <span className="rating-count">(5.0 /7 Rating)</span>
                                    </div>
                                    <div className="course-price">$29.00</div>
                                    <p>Lorem ipsum dolor sit amet consectur adipiscing elit sed eiusmod tempor.</p>
                                    <ul className="course-meta">
                                        <li><i className="icon-24"></i>8 Lessons</li>
                                        <li><i className="icon-25"></i>20 Students</li>
                                    </ul>
                                    <a href="/courses/details/marche-2024" className="edu-btn btn-secondary btn-small">Enrolled <i className="icon-4"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- End Single Course  --> */}
                    {/* <!-- Start Single Course  --> */}
                    <div className="col-md-6 col-lg-4 col-xl-3" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                        <div className="edu-course course-style-1 course-box-shadow hover-button-bg-white">
                            <div className="inner">
                                <div className="thumbnail">
                                    <a href="/courses/details/marche-2024">
                                        <img src="assets/images/course/course-25.jpg" alt="Course Meta"/>
                                    </a>
                                    <div className="time-top">
                                        <span className="duration"><i className="icon-61"></i>Course</span>
                                    </div>
                                </div>
                                <div className="content">
                                    <span className="course-level">Beginner</span>
                                    <h6 className="title">
                                        <a href="#!">The Complete React Developer Course (Hooks and Redux)</a>
                                    </h6>
                                    <div className="course-rating">
                                        <div className="rating">
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                        </div>
                                        <span className="rating-count">(5.0 /7 Rating)</span>
                                    </div>
                                    <div className="course-price">$29.00</div>
                                    <ul className="course-meta">
                                        <li><i className="icon-24"></i>8 Lessons</li>
                                        <li><i className="icon-25"></i>20 Students</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="course-hover-content-wrapper">
                                <button className="wishlist-btn"><i className="icon-22"></i></button>
                            </div>
                            <div className="course-hover-content">
                                <div className="content">
                                    <button className="wishlist-btn"><i className="icon-22"></i></button>
                                    <span className="course-level">Beginner</span>
                                    <h6 className="title">
                                        <a href="/courses/details/marche-2024">The Complete React Developer Course (Hooks and Redux)</a>
                                    </h6>
                                    <div className="course-rating">
                                        <div className="rating">
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                        </div>
                                        <span className="rating-count">(5.0 /7 Rating)</span>
                                    </div>
                                    <div className="course-price">$29.00</div>
                                    <p>Lorem ipsum dolor sit amet consectur adipiscing elit sed eiusmod tempor.</p>
                                    <ul className="course-meta">
                                        <li><i className="icon-24"></i>8 Lessons</li>
                                        <li><i className="icon-25"></i>20 Students</li>
                                    </ul>
                                    <a href="/courses/details/marche-2024" className="edu-btn btn-secondary btn-small">Enrolled <i className="icon-4"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- End Single Course  --> */}
                    {/* <!-- Start Single Course  --> */}
                    <div className="col-md-6 col-lg-4 col-xl-3" data-sal-delay="200" data-sal="slide-up" data-sal-duration="800">
                        <div className="edu-course course-style-1 course-box-shadow hover-button-bg-white">
                            <div className="inner">
                                <div className="thumbnail">
                                    <a href="/courses/details/marche-2024">
                                        <img src="assets/images/course/course-26.jpg" alt="Course Meta"/>
                                    </a>
                                    <div className="time-top">
                                        <span className="duration"><i className="icon-61"></i>Course</span>
                                    </div>
                                </div>
                                <div className="content">
                                    <span className="course-level">Beginner</span>
                                    <h6 className="title">
                                        <a href="#!">Build an app with ASPNET Core & Angular from Scratch Begainer</a>
                                    </h6>
                                    <div className="course-rating">
                                        <div className="rating">
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                        </div>
                                        <span className="rating-count">(5.0 /7 Rating)</span>
                                    </div>
                                    <div className="course-price">$29.00</div>
                                    <ul className="course-meta">
                                        <li><i className="icon-24"></i>8 Lessons</li>
                                        <li><i className="icon-25"></i>20 Students</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="course-hover-content-wrapper">
                                <button className="wishlist-btn"><i className="icon-22"></i></button>
                            </div>
                            <div className="course-hover-content">
                                <div className="content">
                                    <button className="wishlist-btn"><i className="icon-22"></i></button>
                                    <span className="course-level">Beginner</span>
                                    <h6 className="title">
                                        <a href="/courses/details/marche-2024">Build an app with ASPNET Core & Angular from Scratch Begainer</a>
                                    </h6>
                                    <div className="course-rating">
                                        <div className="rating">
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                        </div>
                                        <span className="rating-count">(5.0 /7 Rating)</span>
                                    </div>
                                    <div className="course-price">$29.00</div>
                                    <p>Lorem ipsum dolor sit amet consectur adipiscing elit sed eiusmod tempor.</p>
                                    <ul className="course-meta">
                                        <li><i className="icon-24"></i>8 Lessons</li>
                                        <li><i className="icon-25"></i>20 Students</li>
                                    </ul>
                                    <a href="/courses/details/marche-2024" className="edu-btn btn-secondary btn-small">Enrolled <i className="icon-4"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- End Single Course  --> */}
                    {/* <!-- Start Single Course  --> */}
                    <div className="col-md-6 col-lg-4 col-xl-3" data-sal-delay="250" data-sal="slide-up" data-sal-duration="800">
                        <div className="edu-course course-style-1 course-box-shadow hover-button-bg-white">
                            <div className="inner">
                                <div className="thumbnail">
                                    <a href="/courses/details/marche-2024">
                                        <img src="assets/images/course/course-27.jpg" alt="Course Meta"/>
                                    </a>
                                    <div className="time-top">
                                        <span className="duration"><i className="icon-61"></i>Course</span>
                                    </div>
                                </div>
                                <div className="content">
                                    <span className="course-level">Beginner</span>
                                    <h6 className="title">
                                        <a href="#!">Become a WordPress Developer: Unlocking Power with Code</a>
                                    </h6>
                                    <div className="course-rating">
                                        <div className="rating">
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                        </div>
                                        <span className="rating-count">(5.0 /7 Rating)</span>
                                    </div>
                                    <div className="course-price">$29.00</div>
                                    <ul className="course-meta">
                                        <li><i className="icon-24"></i>8 Lessons</li>
                                        <li><i className="icon-25"></i>20 Students</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="course-hover-content-wrapper">
                                <button className="wishlist-btn"><i className="icon-22"></i></button>
                            </div>
                            <div className="course-hover-content">
                                <div className="content">
                                    <button className="wishlist-btn"><i className="icon-22"></i></button>
                                    <span className="course-level">Beginner</span>
                                    <h6 className="title">
                                        <a href="/courses/details/marche-2024">Become a WordPress Developer: Unlocking Power with Code</a>
                                    </h6>
                                    <div className="course-rating">
                                        <div className="rating">
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                        </div>
                                        <span className="rating-count">(5.0 /7 Rating)</span>
                                    </div>
                                    <div className="course-price">$29.00</div>
                                    <p>Lorem ipsum dolor sit amet consectur adipiscing elit sed eiusmod tempor.</p>
                                    <ul className="course-meta">
                                        <li><i className="icon-24"></i>8 Lessons</li>
                                        <li><i className="icon-25"></i>20 Students</li>
                                    </ul>
                                    <a href="/courses/details/marche-2024" className="edu-btn btn-secondary btn-small">Enrolled <i className="icon-4"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- End Single Course  --> */}
                    {/* <!-- Start Single Course  --> */}
                    <div className="col-md-6 col-lg-4 col-xl-3" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                        <div className="edu-course course-style-1 course-box-shadow hover-button-bg-white">
                            <div className="inner">
                                <div className="thumbnail">
                                    <a href="/courses/details/marche-2024">
                                        <img src="assets/images/course/course-28.jpg" alt="Course Meta"/>
                                    </a>
                                    <div className="time-top">
                                        <span className="duration"><i className="icon-61"></i>Course</span>
                                    </div>
                                </div>
                                <div className="content">
                                    <span className="course-level">Beginner</span>
                                    <h6 className="title">
                                        <a href="#!">Build Responsive Real- World Websites with HTML and CSS</a>
                                    </h6>
                                    <div className="course-rating">
                                        <div className="rating">
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                        </div>
                                        <span className="rating-count">(5.0 /7 Rating)</span>
                                    </div>
                                    <div className="course-price">$29.00</div>
                                    <ul className="course-meta">
                                        <li><i className="icon-24"></i>8 Lessons</li>
                                        <li><i className="icon-25"></i>20 Students</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="course-hover-content-wrapper">
                                <button className="wishlist-btn"><i className="icon-22"></i></button>
                            </div>
                            <div className="course-hover-content">
                                <div className="content">
                                    <button className="wishlist-btn"><i className="icon-22"></i></button>
                                    <span className="course-level">Beginner</span>
                                    <h6 className="title">
                                        <a href="/courses/details/marche-2024">Build Responsive Real- World Websites with HTML and CSS</a>
                                    </h6>
                                    <div className="course-rating">
                                        <div className="rating">
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                        </div>
                                        <span className="rating-count">(5.0 /7 Rating)</span>
                                    </div>
                                    <div className="course-price">$29.00</div>
                                    <p>Lorem ipsum dolor sit amet consectur adipiscing elit sed eiusmod tempor.</p>
                                    <ul className="course-meta">
                                        <li><i className="icon-24"></i>8 Lessons</li>
                                        <li><i className="icon-25"></i>20 Students</li>
                                    </ul>
                                    <a href="/courses/details/marche-2024" className="edu-btn btn-secondary btn-small">Enrolled <i className="icon-4"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- End Single Course  --> */}
                    {/* <!-- Start Single Course  --> */}
                    <div className="col-md-6 col-lg-4 col-xl-3" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                        <div className="edu-course course-style-1 course-box-shadow hover-button-bg-white">
                            <div className="inner">
                                <div className="thumbnail">
                                    <a href="/courses/details/marche-2024">
                                        <img src="assets/images/course/course-29.jpg" alt="Course Meta"/>
                                    </a>
                                    <div className="time-top">
                                        <span className="duration"><i className="icon-61"></i>Course</span>
                                    </div>
                                </div>
                                <div className="content">
                                    <span className="course-level">Beginner</span>
                                    <h6 className="title">
                                        <a href="#!">Vue - The Complete Guide (w/ Router, Vuex, Composition API)</a>
                                    </h6>
                                    <div className="course-rating">
                                        <div className="rating">
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                        </div>
                                        <span className="rating-count">(5.0 /7 Rating)</span>
                                    </div>
                                    <div className="course-price">$29.00</div>
                                    <ul className="course-meta">
                                        <li><i className="icon-24"></i>8 Lessons</li>
                                        <li><i className="icon-25"></i>20 Students</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="course-hover-content-wrapper">
                                <button className="wishlist-btn"><i className="icon-22"></i></button>
                            </div>
                            <div className="course-hover-content">
                                <div className="content">
                                    <button className="wishlist-btn"><i className="icon-22"></i></button>
                                    <span className="course-level">Beginner</span>
                                    <h6 className="title">
                                        <a href="/courses/details/marche-2024">Vue - The Complete Guide (w/ Router, Vuex, Composition API)</a>
                                    </h6>
                                    <div className="course-rating">
                                        <div className="rating">
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                        </div>
                                        <span className="rating-count">(5.0 /7 Rating)</span>
                                    </div>
                                    <div className="course-price">$29.00</div>
                                    <p>Lorem ipsum dolor sit amet consectur adipiscing elit sed eiusmod tempor.</p>
                                    <ul className="course-meta">
                                        <li><i className="icon-24"></i>8 Lessons</li>
                                        <li><i className="icon-25"></i>20 Students</li>
                                    </ul>
                                    <a href="/courses/details/marche-2024" className="edu-btn btn-secondary btn-small">Enrolled <i className="icon-4"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- End Single Course  --> */}
                    {/* <!-- Start Single Course  --> */}
                    <div className="col-md-6 col-lg-4 col-xl-3" data-sal-delay="200" data-sal="slide-up" data-sal-duration="800">
                        <div className="edu-course course-style-1 course-box-shadow hover-button-bg-white">
                            <div className="inner">
                                <div className="thumbnail">
                                    <a href="/courses/details/marche-2024">
                                        <img src="assets/images/course/course-30.jpg" alt="Course Meta"/>
                                    </a>
                                    <div className="time-top">
                                        <span className="duration"><i className="icon-61"></i>Course</span>
                                    </div>
                                </div>
                                <div className="content">
                                    <span className="course-level">Beginner</span>
                                    <h6 className="title">
                                        <a href="#!">Master Microservices with Spring Boot and Spring Cloud</a>
                                    </h6>
                                    <div className="course-rating">
                                        <div className="rating">
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                        </div>
                                        <span className="rating-count">(5.0 /7 Rating)</span>
                                    </div>
                                    <div className="course-price">$29.00</div>
                                    <ul className="course-meta">
                                        <li><i className="icon-24"></i>8 Lessons</li>
                                        <li><i className="icon-25"></i>20 Students</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="course-hover-content-wrapper">
                                <button className="wishlist-btn"><i className="icon-22"></i></button>
                            </div>
                            <div className="course-hover-content">
                                <div className="content">
                                    <button className="wishlist-btn"><i className="icon-22"></i></button>
                                    <span className="course-level">Beginner</span>
                                    <h6 className="title">
                                        <a href="/courses/details/marche-2024">Master Microservices with Spring Boot and Spring Cloud</a>
                                    </h6>
                                    <div className="course-rating">
                                        <div className="rating">
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                        </div>
                                        <span className="rating-count">(5.0 /7 Rating)</span>
                                    </div>
                                    <div className="course-price">$29.00</div>
                                    <p>Lorem ipsum dolor sit amet consectur adipiscing elit sed eiusmod tempor.</p>
                                    <ul className="course-meta">
                                        <li><i className="icon-24"></i>8 Lessons</li>
                                        <li><i className="icon-25"></i>20 Students</li>
                                    </ul>
                                    <a href="/courses/details/marche-2024" className="edu-btn btn-secondary btn-small">Enrolled <i className="icon-4"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- End Single Course  --> */}
                    {/* <!-- Start Single Course  --> */}
                    <div className="col-md-6 col-lg-4 col-xl-3" data-sal-delay="250" data-sal="slide-up" data-sal-duration="800">
                        <div className="edu-course course-style-1 course-box-shadow hover-button-bg-white">
                            <div className="inner">
                                <div className="thumbnail">
                                    <a href="/courses/details/marche-2024">
                                        <img src="assets/images/course/course-31.jpg" alt="Course Meta"/>
                                    </a>
                                    <div className="time-top">
                                        <span className="duration"><i className="icon-61"></i>Course</span>
                                    </div>
                                </div>
                                <div className="content">
                                    <span className="course-level">Beginner</span>
                                    <h6 className="title">
                                        <a href="#!">The Complete Angular Course: Beginner to Advanced</a>
                                    </h6>
                                    <div className="course-rating">
                                        <div className="rating">
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                        </div>
                                        <span className="rating-count">(5.0 /7 Rating)</span>
                                    </div>
                                    <div className="course-price">$29.00</div>
                                    <ul className="course-meta">
                                        <li><i className="icon-24"></i>8 Lessons</li>
                                        <li><i className="icon-25"></i>20 Students</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="course-hover-content-wrapper">
                                <button className="wishlist-btn"><i className="icon-22"></i></button>
                            </div>
                            <div className="course-hover-content">
                                <div className="content">
                                    <button className="wishlist-btn"><i className="icon-22"></i></button>
                                    <span className="course-level">Beginner</span>
                                    <h6 className="title">
                                        <a href="/courses/details/marche-2024">The Complete Angular Course: Beginner to Advanced</a>
                                    </h6>
                                    <div className="course-rating">
                                        <div className="rating">
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                        </div>
                                        <span className="rating-count">(5.0 /7 Rating)</span>
                                    </div>
                                    <div className="course-price">$29.00</div>
                                    <p>Lorem ipsum dolor sit amet consectur adipiscing elit sed eiusmod tempor.</p>
                                    <ul className="course-meta">
                                        <li><i className="icon-24"></i>8 Lessons</li>
                                        <li><i className="icon-25"></i>20 Students</li>
                                    </ul>
                                    <a href="/courses/details/marche-2024" className="edu-btn btn-secondary btn-small">Enrolled <i className="icon-4"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- End Single Course  --> */}
                </div>
                <div className="load-more-btn" data-sal-delay="100" data-sal="slide-up" data-sal-duration="1200">
                    <a href="course-one.html" className="edu-btn">Load More <i className="icon-56"></i></a>
                </div>
            </div>
        </div>
    </div>
  )
}

export default CourseDetails