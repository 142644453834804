import React from 'react'

import InterviewQuestionsContent from '../../components/pages/Interview-questions/InterviewQuestionsContent'

function InterviewQuestions() {
  return (
   
        <InterviewQuestionsContent/>
       
  )
}

export default InterviewQuestions
