

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PaginatedTable from './PaginatedTable';

class TestimonialTable2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      pageSize: 15,
      totalRows: 40,
      data: [
        { name: 'Anna', jobTitle: 'DevOps Engineer', salary: '125k/year' },
        { name: 'Catherine', jobTitle: 'DevOps Engineer', salary: '120k/year' },
        { name: 'Flore', jobTitle: 'DevOps Engineer', salary: '925100k/year' },
        { name: 'Randy', jobTitle: 'DevOps Engineer', salary: '135k/year' },
        { name: 'Doriane', jobTitle: 'DevOps Engineer', salary: '145k/year' },
        { name: 'Fred', jobTitle: 'DevOps Engineer', salary: '135k/year' },
        { name: 'Arman', jobTitle: 'DevOps Engineer', salary: '114k/year' },
        { name: 'Bernol', jobTitle: 'DevOps Engineer', salary: '135k/year' },
        { name: 'Ivo', jobTitle: 'DevOps Engineer', salary: '120k/year' },
        { name: 'Etiendem', jobTitle: 'DevOps Engineer', salary: '145k/year' },
        { name: 'Carine', jobTitle: 'DevOps Engineer', salary: '110k/year' },
        { name: 'yaw', jobTitle: 'DevOps Engineer', salary: '110k/year' },
        { name: 'Patrice', jobTitle: 'DevOps Engineer', salary: '130k/year' },
        { name: 'Ritah', jobTitle: 'DevOps Engineer', salary: '125k/year' },
        { name: 'Adesoji', jobTitle: 'DevOps Engineer', salary: '140k/year' },
        { name: 'Abiodun', jobTitle: 'DevOps Engineer', salary: '123k/year' },
        { name: 'Jemy', jobTitle: 'DevOps Engineer', salary: '115k/year' },
        { name: 'Frank', jobTitle: 'DevOps Engineer', salary: '120k/year' },
        { name: 'Tonka', jobTitle: 'DevOps Engineer', salary: '140k/year' },
        { name: 'Armand', jobTitle: 'DevOps Engineer', salary: '114k/year' },
        { name: 'Ritah', jobTitle: 'DevOps Engineer', salary: '86k/year' },
        { name: 'Cabrel', jobTitle: 'DevOps Engineer', salary: '148k/year' },
        { name: 'Emilie', jobTitle: 'DevOps Consultant', salary: '120k/year' },
        { name: 'Ngozie', jobTitle: 'DevOps Engineer', salary: '130k/year' },
        { name: 'Cabral', jobTitle: 'DevOps Engineer', salary: '80k/year' },
        { name: 'Gilles', jobTitle: 'DevOps Engineer', salary: '105k/year' },
        { name: 'Valette', jobTitle: 'DevOps Engineer', salary: '110k/year' },
        { name: 'Erica', jobTitle: 'DevOps Engineer', salary: '115k/year' },
        { name: 'Olive', jobTitle: 'DevOps Man', salary: '125k/year' },
        { name: 'Olamide', jobTitle: 'DevOps Engineer', salary: '125k/year' },
        { name: 'Michael', jobTitle: 'DevOps Engineer', salary: '145k/year' },
        { name: 'Chris', jobTitle: 'DevOps Engineer', salary: '115k/year' },
        { name: 'Ola', jobTitle: 'DevOps Engineer', salary: '156k/year' },
        { name: 'Rimun', jobTitle: 'DevOps Engineer', salary: '120k/year' },
        { name: 'Ebini', jobTitle: 'DevOps Engineer', salary: '120k/year' },
        { name: 'Priscille', jobTitle: 'DevOps Engineer', salary: '105k/year' },
        { name: 'Mireille', jobTitle: 'DevOps Engineer', salary: '95k/year' },
        { name: 'Abdul', jobTitle: 'DevOps Engineer', salary: '130k/year' },
        { name: 'Ayomide', jobTitle: 'DevOps Engineer', salary: '101k/year' },
        { name: 'Noah', jobTitle: 'DevOps Engineer', salary: '120k/year' },
        { name: 'Eric', jobTitle: 'DevOps Engineer', salary: '144k/year' },
        { name: 'Eugenia', jobTitle: 'DevOps Engineer', salary: '120k/year' },
        { name: 'David', jobTitle: 'DevOps Engineer', salary: '100k/year' },
        { name: 'Faridou', jobTitle: 'DevOps Engineer', salary: '120k/year' },
        { name: 'Marcus', jobTitle: 'DevOps Engineer', salary: '120k/year' },
        { name: 'Gerard', jobTitle: 'DevOps Engineer', salary: '120k/year' },
        { name: 'Tachim', jobTitle: 'DevOps Engineer', salary: '70k/year' },
        { name: 'Yaovi', jobTitle: 'DevOps Engineer', salary: '130k/year' },
        { name: 'Beauty', jobTitle: 'DevOps Engineer', salary: '120k/year' },
        { name: 'Afolabi', jobTitle: 'DevOps Engineer', salary: '135k/year' },
        { name: 'Benjamin', jobTitle: 'DevOps Engineer', salary: '100k/year' },
        { name: 'Romuald', jobTitle: 'DevOps Engineer', salary: '105k/year' },
        { name: 'Yannick', jobTitle: 'DevOps Engineer', salary: '125k/year' },
        { name: 'Samuel', jobTitle: 'DevOps Engineer', salary: '120k/year' },
        { name: 'Cedric', jobTitle: 'DevOps Engineer', salary: '135k/year' },
        { name: 'Emmanuel', jobTitle: 'DevSecOps Engineer', salary: '110k/year' },
        { name: 'Faridou', jobTitle: 'DevOps Engineer', salary: '120k/year' },
        { name: 'Marcus', jobTitle: 'DevOps Engineer', salary: '120k/year' },
        { name: 'Gerard', jobTitle: 'DevOps Engineer', salary: '120k/year' },
        { name: 'Tachim', jobTitle: 'DevOps Engineer', salary: '70k/year' },
        { name: 'Yaovi', jobTitle: 'DevOps Engineer', salary: '130k/year' },
        { name: 'Beauty', jobTitle: 'DevOps Engineer', salary: '120k/year' },
        { name: 'Afolabi', jobTitle: 'DevOps Engineer', salary: '135k/year' },
        { name: 'Benjamin', jobTitle: 'DevOps Engineer', salary: '100k/year' },
        { name: 'Romuald', jobTitle: 'DevOps Engineer', salary: '105k/year' },
        { name: 'Yannick', jobTitle: 'DevOps Engineer', salary: '125k/year' },
        { name: 'Samuel', jobTitle: 'DevOps Engineer', salary: '120k/year' },
        { name: 'Cedric', jobTitle: 'DevOps Engineer', salary: '135k/year' },
        { name: 'Emmanuel', jobTitle: 'DevSecOps Engineer', salary: '110k/year' },
        { name: 'Aditan', jobTitle: 'DevOps Engineer II', salary: '100k/year' },
        { name: 'Serge', jobTitle: 'DevOps Engineer', salary: '135k/year' },
        { name: 'Florine', jobTitle: 'DevOps Engineer', salary: '72k/year' },
        { name: 'Dinga', jobTitle: 'DevOps Engineer', salary: '105k/year' },
        { name: 'Kojo k', jobTitle: 'DevOps Engineer III', salary: '105k/year' },
        { name: 'Excellent', jobTitle: 'DevOps Engineer', salary: '115k/year' },
        { name: 'Rosine', jobTitle: 'DevOps Engineer', salary: '104k/year' },
        { name: 'Arnold', jobTitle: 'DevOps Engineer', salary: '110k/year' },
        { name: 'Apoti', jobTitle: 'DevOps Engineer', salary: '95k/year' },
        { name: 'Rodrigue', jobTitle: 'DevOps Engineer', salary: '130k/year' },
        { name: 'Marcel', jobTitle: 'DevOps Engineer', salary: '130k/year' },
        { name: 'Brice', jobTitle: 'DevOps Engineer', salary: '166k/year' },
        { name: 'Astrid', jobTitle: 'DevOps Engineer', salary: '173k/year' },
        { name: 'Yannick ebo', jobTitle: 'DevOps Engineer', salary: '95k/year' },
        { name: 'Miza', jobTitle: 'DevOps Engineer', salary: '115k/year' },
        { name: 'Victorine', jobTitle: 'DevOps Engineer', salary: '110k/year' },
        { name: 'Fredderick', jobTitle: 'DevOps Engineer', salary: '177k/year' },
        { name: 'Nancy', jobTitle: 'DevOps Engineer', salary: '105k/year' },
        { name: 'Geraldine', jobTitle: 'DevOps Engineer', salary: '115k/year' },
        { name: 'Jean', jobTitle: 'DevOps Engineer', salary: '115k/year' },
        { name: 'Hermine', jobTitle: 'DevOps Engineer', salary: '85k/year' },
        { name: 'Alma', jobTitle: 'DevOps Engineer', salary: '156k/year' },
        { name: 'Jonathan', jobTitle: 'DevOps Engineer', salary: '140k/year' },
        { name: 'Ritah', jobTitle: 'DevOps Engineer', salary: '120k/year' },
        { name: 'Mariama', jobTitle: 'Devops Engineer II', salary: '100.5k/year' },
        { name: 'Laure', jobTitle: 'Engineer IV', salary: '130k/year' },
        { name: 'Paul', jobTitle: 'DevOps Consultant', salary: '125k/year' },
        { name: 'Fabrice', jobTitle: 'DevOps Consultant', salary: '94k/year' },
        { name: 'Sandrine', jobTitle: 'DevOps Consultant', salary: '140k/year' },
        { name: 'Chris', jobTitle: 'DevOps Engineer', salary: '90k/year' },
        { name: 'Ayomide', jobTitle: 'DevOps Engineer', salary: '135k/year' },
        { name: 'Joy', jobTitle: 'DevOps Engineer', salary: '97k/year' },
        { name: 'Tanya', jobTitle: 'DevOps Engineer', salary: '120k/year' },
        { name: 'Stephane', jobTitle: 'DevOps Engineer', salary: '123k/year' },
        { name: 'Francine', jobTitle: 'DevOps Engineer', salary: '80k/year' },
        { name: 'Cabral', jobTitle: 'DevOps Engineer', salary: '80k/year' },
        { name: 'Stephanie', jobTitle: 'DevOps Engineer', salary: '135k/year' },
        { name: 'Christopher', jobTitle: 'DevOps Engineer', salary: '124k/year' },
        { name: 'Larissa', jobTitle: 'DevOps Engineer', salary: '125k/year' },
        { name: 'Bolan', jobTitle: 'DevOps Engineer', salary: '100k/year' },
        { name: 'Joelle', jobTitle: 'DevOps Engineer', salary: '115k/year' },
        { name: 'Mireille', jobTitle: 'DevOps Engineer', salary: '90k/year' },
        { name: 'George', jobTitle: 'DevOps Engineer', salary: '135k/year' },
        { name: 'Ego', jobTitle: 'DevOps Engineer', salary: '120k/year' },
        { name: 'Michele', jobTitle: 'JPM', salary: '160k/year' },
        { name: 'Leon', jobTitle: 'DevOps Engineer', salary: '120k/year' },
        { name: 'Govina', jobTitle: 'DevOps Engineer', salary: '110k/year' },
        { name: 'Catherine', jobTitle: 'DevOps Engineer', salary: '120k/year' },
        { name: 'Christian', jobTitle: 'DevOps Engineer', salary: '120k/year' },
        { name: 'Pauline', jobTitle: 'DevOps Engineer', salary: '124k/year' },
        { name: 'James', jobTitle: 'DevOps Engineer', salary: '140k/year' },
        { name: 'David', jobTitle: 'DevOps Engineer', salary: '110k/year' },
        { name: 'Judith', jobTitle: 'DevOps Engineer', salary: '98k/year' },
        { name: 'Kurtys', jobTitle: 'DevOps Engineer', salary: '100k/year' },
        { name: 'Munyar', jobTitle: 'DevOps Engineer', salary: '55k/year' },
        { name: 'Ayakncha', jobTitle: 'DevOps Engineer', salary: '100k/year' },
        { name: 'Fleur', jobTitle: 'DevOps Engineer', salary: '115k/year' },
        { name: 'Patrice', jobTitle: 'DevOps Engineer', salary: '75k/year' },
        { name: 'Teddy', jobTitle: 'DevOps Engineer', salary: '82k/year' },
        { name: 'Sylvia', jobTitle: 'DevOps Engineer', salary: '105k/year' },
        { name: 'Jean Marie', jobTitle: 'DevOps Engineer', salary: '135k/year' },
        { name: 'Aurelien', jobTitle: 'DevOps Engineer', salary: '125k/year' },
        { name: 'Fanny', jobTitle: 'DevOps Engineer', salary: '105k/year' },
        { name: 'Sulamithe', jobTitle: 'DevOps Containerization Engineer', salary: '95k/year' },
        { name: 'Dagah', jobTitle: 'DevOps Consultant', salary: '135k/year' },
        { name: 'Yannick', jobTitle: 'DevOps Engineer', salary: '120k/year' },
        { name: 'Claude', jobTitle: 'DevOps Engineer', salary: '100k/year' },
        { name: 'Paul', jobTitle: 'DevOps Engineer', salary: '105k/year' },
        { name: 'Edith', jobTitle: 'DevOps Consultant', salary: '92k/year' }

      ],
    };
  }

  nextPage = () => {
    const { currentPage, pageSize, totalRows } = this.state;
    const maxPage = Math.ceil(totalRows / pageSize);
    if (currentPage < maxPage) {
      this.setState((prevState) => ({
        currentPage: prevState.currentPage + 1,
      }));
    }
  };

  previousPage = () => {
    const { currentPage } = this.state;
    if (currentPage > 1) {
      this.setState((prevState) => ({
        currentPage: prevState.currentPage - 1,
      }));
    }
  };

  // render() {
  //   const { currentPage, pageSize, totalRows, data } = this.state;
  //   const startIndex = (currentPage - 1) * pageSize;
  //   const endIndex = startIndex + pageSize;
  //   const visibleData = data.slice(startIndex, endIndex);
  //   const showPreviousLink = currentPage > 1;
  //   const showNextLink = endIndex < totalRows;

  //   return (
  //     <div>
  //       <table id="customers">
  //         <thead>
  //           <tr>
  //             <th>Names</th>
  //             <th>Job title</th>
  //             <th>Salary per year</th>
  //           </tr>
  //         </thead>
  //         <tbody>
  //           {visibleData.map((row, index) => (
  //             <tr key={index}>
  //               <td>{row.name}</td>
  //               <td>{row.jobTitle}</td>
  //               <td>{row.salary}</td>
  //             </tr>
  //           ))}
  //         </tbody>
  //       </table>
  //       <ul className="edu-pagination">
  //         {showPreviousLink && (
  //           <li>
  //             <Link to="#" onClick={this.previousPage} aria-label="Previous">
  //               <i className="icon-west"></i>
  //             </Link >
  //           </li>
  //         )}
  //         <li className="active">
  //           <Link to="#">{currentPage}</Link>
  //         </li>
  //         {showNextLink && (
  //           <li>
  //             <Link to="#" onClick={this.nextPage}>{currentPage + 1}</Link>
  //           </li>
  //         )}
  //         <li className="more-next">
  //           <Link to="#"></Link >
  //         </li>
  //         <li>
  //           <Link to="#">{Math.ceil(totalRows / pageSize)}</Link >
  //         </li>
  //         <li>
  //           <Link to="#" onClick={this.nextPage} aria-label="Next">
  //             <i className="icon-east"></i>
  //           </Link>
  //         </li>
  //       </ul>
  //     </div>
  //   );
  // }
  render() {
    const { currentPage, pageSize, totalRows, data } = this.state;
    const showPreviousLink = currentPage > 1;
    const showNextLink = (currentPage - 1) * pageSize + 15 < totalRows;
  
    return (
      <PaginatedTable
        currentPage={currentPage}
        pageSize={pageSize}
        totalRows={totalRows}
        data={data}
        showPreviousLink={showPreviousLink}
        showNextLink={showNextLink}
        previousPage={this.previousPage}
        nextPage={this.nextPage}
      />
    );
  }
}

export default TestimonialTable2;
