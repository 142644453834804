

const routesPath = {
    home: '/',
    nextSession: '/sessions/next',
    courses: '/courses',
    courseDetails: '/courses/details/marche-2024',
    sessionsDetails: '/sessions/details/ansible-course-2024',
    interviewquestions: '/interview/questions',
    ComingSoon: 'ComingSoon',
    Support: '/submit/ticket',
    ContactUs: '/contact_us',
    CompaniesStudentsWorked: '/companies/students/worked',
    Error404: '/error404',
    Faq: '/faq',
    Testimonials: '/testimonials',
    Feedback: '/feedback',
  
  
   

}

export default routesPath