import React from 'react'

function FormInterviewQuestions() {
  return (
    <form className="rnt-contact-form rwt-dynamic-form" id="contact-form" method="POST" action="mail.php">
        <div className="row row--10">
            <div className="form-group col-12">
                <input type="text" name="contact-name" id="contact-name" placeholder="Your name"/>
            </div>
            <div className="row">
                <div className="form-group col-6">
                    <input type="email" name="contact-email" id="contact-email" placeholder="Enter your email"/>
                </div>
                <div className="form-group col-6">
                    <input type="tel" name="contact-tel" id="contact-email" placeholder="Enter your phone number"/>
                </div>
            </div>
            <div className="form-group col-12">
                <textarea name="contact-message" id="contact-message" cols="30" rows="4" placeholder="Interview Question"></textarea>
            </div>
            <div className="form-group col-12">
                <button className="rn-btn edu-btn btn-medium submit-btn" name="submit" type="submit">Submit<i className="icon-4"></i></button>
            </div>
        </div>
    </form>
  )
}

export default FormInterviewQuestions
