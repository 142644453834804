import React from 'react';
import Side from '../../components/pages/Home/Side';

function Home() {
  return (
    <Side/>
  )
}

export default Home
