import React, { useState } from "react";
import { getUser, logout } from "../../services/Auth";

const Dropdown = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="dropdown">
      <a onClick={toggleDropdown} className="dropdown-toggle">
        <img src="/w/assets/images/user/avatar-2.jpg" alt="user img" className="rounded-circle" width="50" height="50" />
      </a>
      {isOpen && (
        <ul className="dropdown-menu">
          <li className="dropdown-item">
            <div className="flex-shrink-0">
              <img src="/w/assets/images/user/avatar-2.jpg" alt="user img" className="rounded-circle" width="50" height="50" />
            </div>
            <div className="flex-grow-1 ms-3">
              <h6 className="mb-1">{`${getUser().firstName} ${getUser().lastName}`} 🖖</h6>
              <span>{getUser().username}</span>
            </div>
          </li>
          <li className="dropdown-item">
            <a href="/">Setting</a>
          </li>
          <li className="dropdown-item">
            <a href="/">User dashboard</a>
          </li>
          <li className="dropdown-item" onClick={logout}>
            Logout
          </li>
        </ul>
      )}
      
    </div>
  );
};

export default Dropdown;