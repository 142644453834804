import React from 'react';
import BannerCustom from './IndividualPart/BannerCustom';
import CourseDetails from './IndividualPart/CourseDetails/CourseDetails';
import MainSingleCource from './IndividualPart/MoreCourse/MainSingleCource';

function NextSessionContent() {
    return (
    <div>
        {/* <!--=====================================-->
        <!--=       Breadcrumb Area Start      =-->
        <!--=====================================--> */}
        <BannerCustom/>
        {/* <!--=====================================-->
        <!--=     Courses Details Area Start    =-->
        <!--=====================================--> */}
        <CourseDetails/>
        {/* <!--=====================================-->
        <!--=     More Courses Area Start    =-->
        <!--=====================================-->
        <!-- Start Course Area  --> */}
        <MainSingleCource/>
        {/* <!-- End Course Area --> */}
    </div>
  )
}

export default NextSessionContent
