// TestimonialVideo.js
import React from 'react';
import TestimonialHeadingVideo from './TestimonialHeadingVideo';
import TestimonialCarouselVideo from './TestimonialCarouselVideo';

const TestimonialVideo = () => {
  const testimonials = [
    { videoSrc: 'https://www.youtube.com/embed/LjZAG22S_AE?rel=0' },
    { videoSrc: 'https://www.youtube.com/embed/LjZAG22S_AE?rel=0' },
    { videoSrc: 'https://www.youtube.com/embed/LjZAG22S_AE?rel=0' },
  ];

  return (
    <div className="testimonial-area-4">
      <div className="container">
        <div className="row g-5">
          <div className="col-lg-5">
            <TestimonialHeadingVideo
              preTitle="Testimonials"
              title="What Our Students Have To Say"
              description="We are happy to see more than a thousand happy students expressing their testimonies after completing our program. Are you next?"
            />
          </div>
          <div className="col-lg-7 edu-order-md-1">
            <TestimonialCarouselVideo testimonials={testimonials} />
          </div>
        </div>
      </div>
      <ul className="shape-group">
        <li className="shape-1 scene">
          <img data-depth="2" src="assets/images/others/shape-18.png" alt="Shape" />
        </li>
        <li className="shape-2">
          <img src="assets/images/others/map-shape-3.png" alt="Shape" />
        </li>
      </ul>
    </div>
  );
};

export default TestimonialVideo;