// TestimonialCarousel.js
import React from 'react';
import Swiper from 'swiper';
import TestimonialSlideVideo from './TestimonialSlideVideo';
// import TestimonialCarouselVideo from 'TestimonialCarouselVideo';

const TestimonialCarouselVideo= ({ testimonials }) => {
  React.useEffect(() => {
    new Swiper('.gym-testimonial-activator', {
      // Swiper options
      loop: true,
      navigation: {
        nextEl: '.swiper-btn-nxt',
        prevEl: '.swiper-btn-prv',
      },
    });
  }, []);

  return (
    <div className="gym-testimonial-activator swiper">
      <div className="swiper-wrapper">
        {testimonials.map((testimonial, index) => (
          <div className="swiper-slide" key={index}>
            <TestimonialSlideVideo videoSrc={testimonial.videoSrc} />
          </div>
        ))}
      </div>
      
    </div>
  );
};

export default TestimonialCarouselVideo;