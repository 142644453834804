// TestimonialSlideVideo.js
import React from 'react';

const TestimonialSlideVideo = ({ videoSrc }) => {
  return (
    <div className="testimonial-slide testimonial-style-2">
      <iframe
        className="popup-icon video-popup-activation"
        id="video-popup-activation"
        title="student testimonial"
        width="100%"
        height="400px"
        src={videoSrc}
        frameBorder="0"
        allow="autoplay; encrypted-media"
        allowFullScreen
        style={{ borderRadius: '10px' }}
      ></iframe>
    </div>
  );
};

export default TestimonialSlideVideo;