import React from 'react'
import SingleCourse from './SingleCourse'

function MainSingleCource() {
  return (
    <div className="gap-bottom-equal">
        <div className="container">
            <div className="section-title section-left" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                <h3 className="title">More Courses for You</h3>
            </div>
            <div className="row g-5">
                {/* <!-- Start Single Course  --> */}
                <SingleCourse/>
                <SingleCourse/>
                <SingleCourse/>
                {/* <!-- End Single Course  -->*/}

            </div>
        </div>
    </div>
  )
}

export default MainSingleCource
